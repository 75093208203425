// @ts-nocheck

import React from "react";
import { useLoading } from "../context/loading-context";

interface Props {
  message: string;
  type: any;
  hideInfoView: () => void;
}

const Info: React.FC<Props> = ({ message, type = "", hideInfoView }) => {
  const { loader, setLoader } = useLoading();

  React.useEffect(() => {
    setLoader(false);
  }, []);

  var classInfo = "tw-success tw-msg-checkmark";

  if (type === "error") classInfo = "tw-info tw-msg-warning3";
  if (type === "success") classInfo = "tw-info tw-msg-check-circle2";
  if (type === "warning") classInfo = "tw-info tw-msg-warning3";

  return (
    <div id="twInfoContentView">
      <div>
        <ul
          className="ui-listview ui-listview-inset ui-corner-all ui-shadow"
          id="twInfoView"
          data-role="listview"
          data-inset="true"
        >
          <li
            className="ui-li-divider ui-bar-inherit ui-first-child"
            data-role="list-divider"
            role="heading"
          >
            Info
          </li>
          <li className="ui-li-static ui-body-inherit">
            <div className={`${classInfo}`} />
            <div className="tw-popup-box"> {message}</div>
          </li>
          <li className="ui-li-static ui-body-inherit ui-last-child">
            <div className="ui-btn ui-input-btn ui-btn-e ui-corner-all ui-shadow ui-mini ui-icon-check ui-btn-icon-right">
              OK
              <input
                type="button"
                data-iconpos="right"
                data-icon="check"
                data-mini="true"
                onClick={hideInfoView}
                defaultValue="OK"
              />
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Info;
