// @ts-nocheck

import React, { useState } from "react";
import { Link, useParams, useLocation, Outlet } from "react-router-dom";
import { Tab } from "@headlessui/react";
import Header from "../../components/Header";
import Payement from "./Payement";
import Details from "./Details";
import List from "./List";
import PopupInfo from "../../components/popup-info";
import { numberFormat } from "../../utils/date-utils";
import { useQuery } from "react-query";
import { api_cms } from "../../context/auth/auth-provider";
import { useLoading } from "../../context/loading-context";

export default function Management({ type }) {
  const { loader, setLoader } = useLoading();

  const [isSuccess, setIsSuccess] = useState({
    state: false,
    message: null,
    type: null,
  });

  const [isInfo, setIsInfo] = useState(false);

  const [isOK, setIsOK] = useState(false);

  const [active, setActive] = useState("1");

  const handleClick = (event) => {
    setActive(event.target.id);
  };

  const { userID }: { userID: string } = useParams();

  const { data, isLoading, isError, error, refetch } = useQuery(
    `user/${userID}`,
    async (data) => {
      return api_cms.get(`user/${userID}`);
    }
  );

  React.useEffect(() => {
    setLoader(isLoading);
  }, [setLoader]);

  const handleFocus = (event) => {
    let target = event.target.classList;

    target.remove("ui-state-focus");
    target.add("ui-state-focus");
  };

  if (isLoading) {
    return (
      <>
        <Header text={`${type} management`}></Header>
      </>
    );
  }

  if (isSuccess.state && isSuccess.type === "info") {
    return (
      <>
        <Header text={`info`}>
          <PopupInfo
            message={isSuccess.message}
            type="info"
            setIsOk={setIsOK}
            setIsSuccess={setIsSuccess}
            isOk={isOK}
          />
        </Header>
      </>
    );
  }

  return (
    <>
      <Header
        text={`${type} management`}
        isSuccessConfig={isSuccess}
        setIsSuccess={setIsSuccess}
      >
        <div id="twMainContentView">
          <div className="ui-grid-a grid-medium">
            <div className="ui-block-a">
              <span>Nom d'utilisateur: </span>
              <span
                className={`tw-bold account-holder  user-${
                  data?.user.status === true ? "active" : "blocked"
                }`}
              >
                {data?.user.username}
              </span>
            </div>
            <div className="ui-block-b">
              <span>Montant sur compte: </span>
              <span id="saldo-box" className="tw-bold">
                {numberFormat(data?.user.balance, 2)}
              </span>
            </div>
          </div>
          <div
            data-role="tabs"
            id="playerDetailsNav"
            style={{ marginTop: "4px" }}
            className="ui-tabs ui-widget ui-widget-content ui-corner-all"
          >
            <Tab.Group as="div" className="ui-navbar">
              <Tab.List
                as="ul"
                className="ui-grid-b ui-tabs-nav ui-helper-reset ui-helper-clearfix ui-widget-header ui-corner-all"
              >
                <Tab
                  as="li"
                  className="ui-block-a ui-state-default ui-corner-top"
                  tabIndex={-1}
                  role="tab"
                  aria-controls="payment"
                  style={{ outline: "none" }}
                  onFocus={(e) => handleFocus(e)}
                >
                  {({ selected }) => (
                    <div
                      tabIndex={-1}
                      className={`ui-state-persist ui-link ui-btn ui-tabs-anchor ${
                        selected ? "ui-btn-active" : ""
                      }`}
                      style={{ outline: "none" }}
                      role="presentation"
                    >
                      Payment
                    </div>
                  )}
                </Tab>
                <Tab
                  as="li"
                  className="ui-block-b ui-state-default ui-corner-top"
                  tabIndex={-1}
                  role="tab"
                  aria-controls="payment"
                  style={{ outline: "none" }}
                  onFocus={(e) => handleFocus(e)}
                >
                  {({ selected }) => (
                    <div
                      tabIndex={-1}
                      className={`ui-state-persist ui-link ui-btn ui-tabs-anchor ${
                        selected ? "ui-btn-active" : ""
                      }`}
                      style={{ outline: "none" }}
                      role="presentation"
                    >
                      Details
                    </div>
                  )}
                </Tab>
                <Tab
                  as="li"
                  className="ui-block-c ui-state-default ui-corner-top"
                  tabIndex={-1}
                  role="tab"
                  aria-controls="payment"
                  style={{ outline: "none" }}
                  onFocus={(e) => handleFocus(e)}
                >
                  {({ selected }) => (
                    <div
                      tabIndex={-1}
                      className={`ui-state-persist ui-link ui-btn ui-tabs-anchor ${
                        selected ? "ui-btn-active" : ""
                      }`}
                      style={{ outline: "none" }}
                      role="presentation"
                    >
                      {data?.p === "Player_2s" ? "Players" : data?.p}
                    </div>
                  )}
                </Tab>
              </Tab.List>

              <Tab.Panels>
                <Tab.Panel>
                  <Payement
                    user={data?.user}
                    refetch={refetch}
                    setIsSuccess={setIsSuccess}
                    setLoader={setLoader}
                  />
                </Tab.Panel>
                <Tab.Panel>
                  <Details
                    refetch={refetch}
                    user={data?.user}
                    setIsOK={setIsOK}
                    setIsSuccess={setIsSuccess}
                    setLoader={setLoader}
                  />
                </Tab.Panel>
                <Tab.Panel>
                  <List
                    isLink={data?.p === "Player_2s" ? true : false}
                    childs={data?.childs}
                    c_sum={data?.c_sum}
                  />
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </div>
        </div>
      </Header>
    </>
  );
}
