// @ts-nocheck

import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import Info from "../../components/info";
import Header from "../../components/Header";
import { useMutation } from "react-query";
import { Slider } from "rsuite";
import { api_cms } from "../../context/auth/auth-provider";
import { useAuth } from "../../context/auth/auth-context";

import "rsuite/dist/rsuite-no-reset.min.css";
import "./style.css";

const CreateVoucher = React.memo(function CreateVoucher({}) {
  const navigate = useNavigate();

  const { user, organization_data, refreshUserSimple } = useAuth();

  const redirectHome = () => {
    navigate("/");
  };

  const rangeValues = [10, 20, 30, 40, 50, 100, 150, 200, 250, 300];

  const [selectedRange, setSelectedRange] = React.useState(2);

  const handleRangeChange = (event) => {
    const newValue = parseInt(event.target.value);

    setSelectedRange(newValue);
  };

  let amount_list = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 100, 150];

  const [selectedAmount, setSelectedAmount] = React.useState(0);
  const alpha_array = ["a", "b", "c"];

  const setAmount = (event, amount) => {
    event.preventDefault();

    let value = parseInt(amount);
    setSelectedAmount(value);
  };

  const resetAmount = () => {
    setSelectedAmount(0);
  };

  const { mutate, data, isLoading, isSuccess, isError, error, reset } =
    useMutation(async (data) => api_cms.post("vouchers/create", data));

  function handleSubmit() {
    if (selectedAmount > 0) {
      let amount = selectedAmount;

      let count = rangeValues[selectedRange];

      mutate({ amount, count });
    }
  }

  if (isError) {
    return (
      <>
        <Header text={`info`}>
          <Info message={error.message} type="warning" hideInfoView={reset} />
        </Header>
      </>
    );
  } else if (isSuccess) {
    return (
      <>
        <Header text={`info`}>
          <Info
            message={data.message}
            type="success"
            hideInfoView={redirectHome}
          />
        </Header>
      </>
    );
  }

  //5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 100]
  return (
    <>
      <Header text={`Vouchers register - Vouchers create`}>
        <div id="twMainContentView">
          <div
            id="payment"
            aria-labelledby="ui-id-16"
            className="ui-tabs-panel ui-widget-content ui-corner-bottom"
            role="tabpanel"
            aria-expanded="true"
            aria-hidden="false"
          >
            <div id="alternatingBlock">
              <div>
                <span
                  className="tw-bold"
                  style={{ fontSize: "12px" }}
                  htmlFor="amount"
                >
                  Montant :
                </span>
              </div>

              <div className="ui-grid-b">
                {amount_list &&
                  amount_list.map((amount, index) => {
                    const valueIndex = index % alpha_array.length;
                    const alpha = alpha_array[valueIndex];

                    return (
                      <div key={index} className={`ui-block-${alpha}`}>
                        <button
                          type="button"
                          className={`payment-chip ui-btn ui-shadow ui-corner-all ${
                            selectedAmount === amount ? "selected" : ""
                          } `}
                          onClick={(e) => setAmount(e, amount)}
                        >
                          <span style={{ fontWeight: "bold" }}>{amount}</span>
                          <span style={{ opacity: "0.6", fontSize: "12px" }}>
                            {" "}
                            {organization_data?.currency}
                          </span>
                        </button>
                      </div>
                    );
                  })}
              </div>
              <div className="ui-field-contain">
                <span
                  className="tw-bold"
                  style={{ fontSize: "12px" }}
                  htmlFor="amount"
                >
                  Total :
                </span>
              </div>
              <div>
                <Slider
                  min={0}
                  max={rangeValues.length - 1}
                  value={selectedRange}
                  className="custom-slider"
                  handleStyle={{
                    borderRadius: 10,
                    color: "#fff",
                    fontSize: 14,
                    fontWeight: "bold",
                    width: 32,
                    height: 22,
                  }}
                  graduated
                  progress
                  tooltip={false}
                  handleTitle={rangeValues[selectedRange]}
                  onChange={setSelectedRange}
                />
              </div>
              <div className="ui-field-contain">
                <div
                  onClick={handleSubmit}
                  className="ui-btn ui-input-btn ui-btn-f ui-corner-all ui-shadow ui-mini ui-icon-plus ui-btn-icon-right"
                >
                  Create
                  <input
                    type="button"
                    data-mini="true"
                    defaultValue="Retrait"
                    data-theme="f"
                    data-icon="arrow-u"
                    data-iconpos="right"
                    data-transfermode={2}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Header>
    </>
  );
});

export default CreateVoucher;
