// @ts-nocheck

import React from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { numberFormat } from "../../utils/date-utils";

export default function List({ isLink, childs, c_sum }) {
  return (
    <div
      id="topPlayers"
      aria-labelledby="ui-id-27"
      className="ui-tabs-panel ui-widget-content ui-corner-bottom"
      role="tabpanel"
      aria-expanded="true"
      aria-hidden="false"
      style={{ display: "block" }}
    >
      <div style={{ marginTop: "12px" }}>
        <div className="ui-input-search ui-body-inherit ui-corner-all ui-mini ui-shadow-inset ui-input-has-clear">
          <input id="searchPlayers" data-mini="true" data-type="search" />
          <a
            href="#"
            tabIndex={-1}
            aria-hidden="true"
            className="ui-input-clear ui-btn ui-icon-delete ui-btn-icon-notext ui-corner-all ui-input-clear-hidden"
            title="Clear text"
          >
            Clear text
          </a>
        </div>
      </div>
      <ul
        data-role="listview"
        data-inset="true"
        className="ul-simple transactional-list ui-listview ui-listview-inset ui-corner-all ui-shadow"
        data-filter="true"
        data-input="#searchPlayers"
      >
        <li
          data-role="list-divider"
          className="tw-ui-li-divider ui-li-divider ui-bar-b ui-bar-inherit ui-first-child"
          role="heading"
        >
          <div className="ui-grid-a tw-fsize11">
            <div className="ui-block-a tw-inline">Nom d'utilisateur</div>
            <div className="ui-block-b tw-inline tw-text-rintent10">Amount</div>
          </div>
        </li>
        <li className="pt-row ui-li-static ui-body-inherit">
          <div className="ui-grid-a tw-fsize11 text-g">
            <div className="ui-block-a tw-inline tw-bold">
              Total : {childs.length}
            </div>
            <div className="ui-block-b tw-inline tw-text-right tw-bold">
              {numberFormat(c_sum, 2)}
            </div>
          </div>
        </li>
        {childs &&
          childs.map((user: any, index: any) => (
            <li className="pt-row" key={index}>
              <Link
                className="a-simple ui-link ui-btn ui-icon-arrow-r ui-btn-icon-right ui-shadow ui-corner-all ui-mini"
                to={
                  isLink
                    ? `/${user.role}/${user.role}-balances/management/${user.id}`
                    : ""
                }
                data-role="button"
                data-icon="arrow-r"
                data-iconpos="right"
                data-mini="true"
                role="button"
              >
                <div className="ui-grid-a">
                  <div
                    className="ui-block-a tw-break-word tw-fsize11"
                    style={{ width: "75%" }}
                  >
                    <div className="user-active">{user.username}</div>
                    <div className="tw-fsize11 tw-fwn">
                      {user.role} ID : {user.id}
                    </div>
                  </div>
                  <div
                    className="ui-block-b tw-text-right tw-fwn tw-fsize11"
                    style={{ width: "25%" }}
                  >
                    {numberFormat(user.sold_sport, 2)}
                  </div>
                </div>
              </Link>
            </li>
          ))}
        <li className="ui-li-static ui-body-inherit ui-last-child">
          <div className="ui-btn ui-input-btn ui-btn-e ui-corner-all ui-shadow ui-mini ui-icon-arrow-u ui-btn-icon-right">
            Top
            <input
              type="button"
              data-theme="e"
              defaultValue="Top"
              data-icon="arrow-u"
              data-iconpos="right"
              data-mini="true"
            />
          </div>
        </li>
      </ul>
    </div>
  );
}
