// @ts-nocheck

import React from "react";

const Spinner: React.FC = ({ isLoading }) => {
  React.useEffect(() => {
    const el = document.querySelector(".ui-mobile");

    if (isLoading) {
      el.classList.add("ui-loading");
    } else {
      el.classList.remove("ui-loading");
    }
  }, [isLoading]);

  return (
    <div className=" ui-loader ui-corner-all ui-body-a ui-loader-default">
      <span className="ui-icon-loading" />
      <h1>loading</h1>
    </div>
  );
};

export default Spinner;
