// @ts-nocheck

import React, { useState } from "react";
import DatePicker from "./DatePicker";
import { useLoading } from "../../context/loading-context";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  EndDateState,
  StartDateState,
  ToggleDateState,
  numberFormat,
} from "../../utils/date-utils";
import Header from "../../components/Header";
import { useQuery, useMutation } from "react-query";
import { api_cms } from "../../context/auth/auth-provider";

export default function Cashier() {
  const { loader, setLoader } = useLoading();
  const [whichReq, setWhichReq] = useState(1);

  const [toggleDate, setToggleDate] = useRecoilState(ToggleDateState);
  const StartDate = useRecoilValue(StartDateState);
  const EndDate = useRecoilValue(EndDateState);

  const [cashData, setCashData] = useState({
    dep: 0,
    ret: 0,
    diff: 0,
  });

  const {
    data: queryData,
    isLoading: queryIsLoading,
    isError: queryIsError,
    error: queryError,
  } = useQuery(
    `cashier-history`,
    async (queryData) => api_cms.post(`cashier-history`),
    {
      onSuccess: (queryData) => {
        let diff = queryData.total_deposit - queryData.total_retrait;
        setCashData({
          dep: queryData.total_deposit,
          ret: queryData.total_retrait,
          diff: diff,
        });
      },
    }
  );

  const {
    mutate,
    data: mutationData,
    isLoading: mutationIsLoading,
    mutationError,
    reset,
  } = useMutation(async (data) => api_cms.post("cashier-history", data), {
    onSuccess: (mutationData) => {
      let diff = mutationData.total_deposit - mutationData.total_retrait;
      setCashData({
        dep: mutationData.total_deposit,
        ret: mutationData.total_retrait,
        diff: diff,
      });
    },
  });

  React.useEffect(() => {
    if (whichReq === 1) {
      setLoader(queryIsLoading);
    } else {
      setLoader(mutationIsLoading);
    }
  });

  const submitTransaction = () => {
    if (mutationIsLoading) return;
    setWhichReq(2);
    mutate({
      sdate: StartDate,
      edate: EndDate,
    });
  };

  const [togglePeriod, setTogglePeriod] = useState(true);

  const toggleFnDate = (period) => {
    setToggleDate(!toggleDate);
    period ? setTogglePeriod(true) : setTogglePeriod(false);
  };

  return (
    <>
      <Header text={`Cash register - Cashier transactions`}>
        <div id="twMainContentView">
          <ul data-role="listview" data-inset="false" className="ui-listview">
            <li
              data-role="list-divider"
              className="tw-ui-li-divider ui-li-divider ui-bar-b ui-first-child ui-last-child"
              data-theme="b"
              role="heading"
            >
              Cashier transactions
            </li>
          </ul>
          <ul
            data-role="listview"
            data-inset="false"
            style={{ paddingTop: "17px" }}
            className="ui-listview"
          >
            <li className="ui-field-contain ui-li-static ui-body-inherit ui-first-child">
              <label htmlFor="fdate" style={{ fontSize: "14px" }}>
                Start date
              </label>
              <div className="ui-input-text ui-body-inherit ui-corner-all ui-mini ui-shadow-inset ui-input-has-clear">
                <input
                  type="text"
                  name="fdate"
                  id="fdate"
                  data-role="datebox"
                  data-mini="true"
                  value={StartDate}
                  min="2021-10-27"
                  data-valgroup="dFilterForms"
                  data-valfunction="dateValid"
                  readOnly="readonly"
                  onFocus={() => toggleFnDate(true)}
                />
                <a
                  className="ui-input-clear ui-btn ui-icon-calendar ui-btn-icon-notext ui-corner-all"
                  title="Open Date Picker"
                  onClick={() => toggleFnDate(true)}
                >
                  Open Date Picker
                </a>
              </div>
            </li>
            <li className="ui-field-contain ui-li-static ui-body-inherit">
              <label htmlFor="edate" style={{ fontSize: "14px" }}>
                Ending date
              </label>
              <div className="ui-input-text ui-body-inherit ui-corner-all ui-mini ui-shadow-inset ui-input-has-clear">
                <input
                  type="text"
                  name="edate"
                  id="edate"
                  data-role="datebox"
                  data-mini="true"
                  value={EndDate}
                  min="2021-10-27"
                  data-valgroup="dFilterForms"
                  data-valfunction="dateValid"
                  readOnly="readonly"
                  onFocus={() => toggleFnDate(false)}
                />
                <a
                  className="ui-input-clear ui-btn ui-icon-calendar ui-btn-icon-notext ui-corner-all"
                  title="Open Date Picker"
                  onClick={() => toggleFnDate(false)}
                >
                  Open Date Picker
                </a>
              </div>
            </li>
            <li className="ui-field-contain ui-li-static ui-body-inherit ui-last-child">
              <div
                onClick={submitTransaction}
                className="ui-btn ui-input-btn ui-btn-c ui-corner-all ui-shadow ui-mini ui-icon-search ui-btn-icon-right"
              >
                Display
                <input
                  type="button"
                  value="Display"
                  data-icon="search"
                  data-theme="c"
                  data-iconpos="right"
                  data-mini="true"
                />
              </div>
            </li>
          </ul>
          <ul
            className="tw-ul-overview ul-simple transactional-list ui-listview ui-group-theme-a"
            data-role="listview"
            data-theme="a"
            data-count-theme="a"
            data-inset="false"
            style={{ marginTop: "16px" }}
          >
            <li
              data-role="list-divider"
              className="tw-ui-li-divider ui-li-divider ui-bar-b  ui-bar-a ui-first-child"
              role="heading"
            >
              <div className="ui-grid-b tw-fsize11">
                <div className="ui-block-a tw-inline"></div>
                <div className="ui-block-b tw-inline">Term</div>
                <div className="ui-block-c tw-inline tw-text-rintent10">
                  Amount
                </div>
              </div>
            </li>
            <li className="pt-row ui-li-static ui-body-inherit">
              <div className="ui-grid-b tw-fsize11">
                <div className="ui-block-a tw-inline"></div>
                <div className="ui-block-b tw-inline tw-break-word">
                  Dépôt totale en espèces
                </div>
                <div className="ui-block-c tw-inline tw-text-right tw-break-word user-active">
                  {numberFormat(cashData.dep, 2)}
                </div>
              </div>
            </li>
            <li className="pt-row ui-li-static ui-body-inherit">
              <div className="ui-grid-b tw-fsize11">
                <div className="ui-block-a tw-inline"></div>
                <div className="ui-block-b tw-inline tw-break-word">
                  Retrait totale en espèces
                </div>
                <div className="ui-block-c tw-inline tw-text-right tw-break-word user-inactive">
                  -{numberFormat(cashData.ret, 2)}
                </div>
              </div>
            </li>
            <li className="pt-row ui-li-static ui-body-inherit ui-last-child">
              <div className="ui-grid-a tw-fsize11">
                <div className="ui-block-a tw-inline"></div>
                <div
                  className={`ui-block-b tw-inline tw-text-right tw-bold  user-${
                    cashData.diff < 0 ? "inactive" : "active"
                  }`}
                >
                  {numberFormat(cashData.diff, 2)}
                </div>
              </div>
            </li>
          </ul>
        </div>
      </Header>
      {toggleDate && <DatePicker dateOption={togglePeriod} />}
    </>
  );
}
