// @ts-nocheck
import React, { useState, useEffect } from "react";
import {
  Route,
  Navigate,
  Outlet,
  BrowserRouter,
  Routes,
} from "react-router-dom";
import { RecoilRoot } from "recoil";

//main components

import CreateUser from "./pages/common/create-user";
import UserTree from "./pages/common/user-tree";
import UsersList from "./pages/common/users-list";
import Login from "./pages/login/Login";
import Menu from "./pages/menu/Menu";
import TransactionHistory from "./pages/transactions/TransactionHistory";
import Cashier from "./pages/transactions/Cashier";
import Management from "./pages/management/Management";
import Payement from "./pages/management/Payement";
import Details from "./pages/management/Details";
import List from "./pages/management/List";
import ReportBetslip from "./pages/reclamation/ReportBetslip";
import ReportedBetslips from "./pages/reclamation/ReportedBetslips";
import FullPageSpinner from "./components/full-page-spinner";

import CreateVoucher from "./pages/vouchers/create-vouchers";
import VouchersTransactions from "./pages/vouchers/vouchers-transactions";
import VouchersManagement from "./pages/vouchers/vouchers-management";

import { useAuth } from "./context/auth/auth-context";

const userAllowedRoles = (role) => {
  let roles_permissions = {
    super: ["admin", "transactions", "vouchers", "admin-cercuit"],
    admin: ["agent", "shop", "player", , "transactions", "admin-cercuit"],
    agent: ["shop", "player", , "transactions", "admin-cercuit"],
    shop: ["player", , "transactions"],
  };

  return roles_permissions[role];
};

const ProtectedRoutes = (props: any) => {
  const { user } = useAuth();

  if (!user) {
    return <Navigate to="/" />;
  }

  let allowed_pages = userAllowedRoles(user.role);

  if (!allowed_pages.includes(props.page)) {
    return <Navigate to="/" />;
  }

  return <Outlet />;
};

const RootStack: React.FC = () => {
  const { user } = useAuth();

  const [isLoading, setLoading] = useState(true);

  function fakeRequest() {
    return new Promise((resolve) => setTimeout(() => resolve(), 1000));
  }

  useEffect(() => {
    fakeRequest().then(() => {
      const el = document.querySelector(".ui-loading");
      if (el) {
        el.classList.remove("ui-loading");
        document.body.className = "ui-mobile-viewport ui-overlay-a bnormal";
        setLoading(false);
      }
    });
  }, []);

  if (isLoading) {
    return <FullPageSpinner />;
  }

  return (
    <>
      <Routes>
        <Route path="/" element={user ? <Menu /> : <Login />} />

        <Route path="admin" element={<ProtectedRoutes page="admin" />}>
          <Route path="new-admin" element={<CreateUser type={"Admin"} />} />
          <Route path="admin-balances" element={<UsersList type={"Admin"} />} />
          <Route
            path="admin-balances/management/:userID"
            element={<Management type={"Admin"} />}
          />
        </Route>

        <Route path="agent" element={<ProtectedRoutes page="agent" />}>
          <Route path="new-agent" element={<CreateUser type={"Agent"} />} />
          <Route path="agent-balances" element={<UsersList type={"Agent"} />} />
          <Route
            path="agent-balances/management/:userID"
            element={<Management type={"Agent"} />}
          />
        </Route>

        <Route path="shop" element={<ProtectedRoutes page="shop" />}>
          <Route path="new-shop" element={<CreateUser type={"Shop"} />} />
          <Route path="shop-balances" element={<UsersList type={"Shop"} />} />
          <Route
            path="shop-balances/management/:userID"
            element={<Management type={"Shop"} />}
          />
        </Route>

        <Route path="player" element={<ProtectedRoutes page="player" />}>
          <Route path="new-player" element={<CreateUser type={"Player"} />} />
          <Route
            path="player-balances"
            element={<UsersList type={"Player"} />}
          />
          <Route
            path="player-balances/management/:userID"
            element={<Management type={"Player"} />}
          />
        </Route>

        <Route path="vouchers" element={<ProtectedRoutes page="vouchers" />}>
          <Route path="create" element={<CreateVoucher />} />
          <Route path="transactions" element={<VouchersTransactions />} />
          <Route path="transactions/:trId" element={<VouchersManagement />} />
        </Route>

        <Route
          path="admin-cercuit"
          element={<ProtectedRoutes page="admin-cercuit" />}
        >
          <Route path="" element={<UserTree />} />
        </Route>

        <Route
          path="partners"
          element={<ProtectedRoutes page="transactions" />}
        >
          <Route path="transactions" element={<TransactionHistory />} />
          <Route path="cashier" element={<Cashier />} />
        </Route>
      </Routes>
    </>
  );
};

const App = () => (
  <RecoilRoot>
    <RootStack />
  </RecoilRoot>
);
export default App;
