// @ts-nocheck
import moment from "moment";

const { atom } = require("recoil");

export function getDaysInMonth(year, month) {
  return new Date(year, month, 0).getDate();
}

export function showAsFloat(num: any, n: number) {
  return !isNaN(+num) ? (+num).toFixed(n || 2) : num;
}

export function formatDate2(date_match: string) {
  return moment(date_match).format("YYYY-MM-DD HH:mm");
}

export function formatDate(date_match: string) {
  return moment(date_match).format("YYYY-MM-DD HH:mm:ss");
}

export function formatDateTransaction(date_match: string) {
  return moment(date_match).format("DD.MM.YYYY HH:mm:ss");
}

export function numberFormat(number, dec, dsep, tsep) {
  if (isNaN(number)) return "";
  number = number.toFixed(dec || 0);
  var pindex = number.indexOf("."),
    fnums,
    decimals,
    parts = [];
  if (pindex > -1) {
    fnums = number.substring(0, pindex).split("");
    decimals = (dsep || ".") + number.substr(pindex + 1);
  } else {
    fnums = number.split("");
    decimals = "";
  }
  do {
    parts.unshift(fnums.splice(-3, 3).join(""));
  } while (fnums.length);
  return parts.join(tsep || " ") + decimals;
}

export function range(start, count) {
  if (arguments.length === 1) {
    count = start;
    start = 0;
  }

  var foo = [];
  for (var i = 0; i < count; i++) {
    foo.push(start + i);
  }
  return foo;
}

export const StartDateState = atom({
  key: "StartDate",
  default: moment().format("DD.MM.YYYY"),
});
export const EndDateState = atom({
  key: "EndDate",
  default: moment().format("DD.MM.YYYY"),
});

export const ToggleDateState = atom({
  key: "ToggleDate",
  default: false,
});

export const AmountState = atom({
  key: "Amount",
  default: 0,
});
