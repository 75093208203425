// @ts-nocheck

import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Switch } from "@headlessui/react";
import { formatDate2 } from "../../utils/date-utils";
import { useMutation } from "react-query";
import { api_cms } from "../../context/auth/auth-provider";
import Spinner from "../../components/spinner";
import { getReferenceId } from "../../utils/orgs-utils";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { VouchersPrint } from "./vouchers-print";

function totalVouchersAmount(vouchers) {
  const sum = vouchers.reduce((total, current) => total + current.amount, 0);

  return sum;
}

function totalVouchersRedeemedAmount(vouchers) {
  const sumRedeemedAmount = vouchers.reduce((total, current) => {
    if (current.is_redeemed) {
      return total + current.amount;
    }
    return total;
  }, 0);

  return sumRedeemedAmount;
}

function totalVouchersNotRedeemedAmount(vouchers) {
  const sumRedeemedAmount = vouchers.reduce((total, current) => {
    if (current.is_redeemed === false) {
      return total + current.amount;
    }
    return total;
  }, 0);

  return sumRedeemedAmount;
}

export default function Details({
  refetch,
  transaction,
  setIsSuccess,
  setIsOK,
  setLoader,
  organization_data,
}) {
  let { currency } = organization_data;

  const printDomRef = React.createRef();

  function openPrintPage() {}

  return (
    <>
      <div
        id="VouchersDetails"
        aria-labelledby="ui-id-20"
        className="ui-tabs-panel ui-widget-content ui-corner-bottom"
        role="tabpanel"
        aria-expanded="true"
        aria-hidden="false"
        style={{ display: "block" }}
      >
        <ul
          data-role="listview"
          style={{ marginTop: "8px" }}
          data-inset="true"
          className="ul-simple ui-listview ui-listview-inset ui-corner-all ui-shadow"
        >
          <li className="ui-li-static ui-body-inherit ui-first-child">
            <span className="flex justify-start py-1 space-x-1 h-8">
              {" "}
              <span className="tw-bold">Active : </span>{" "}
              <SwitchButton
                transaction_id={transaction.id}
                status={transaction.status}
                refetch={refetch}
                setLoader={setLoader}
              />
            </span>
          </li>
          <li className="ui-li-static ui-body-inherit">
            <span>N° Serie :</span>
            <span className="tw-bold"> {getReferenceId(transaction.id)} </span>
          </li>

          <li className="ui-li-static ui-body-inherit">
            <span>Total coupons :</span>
            <span className="tw-bold"> {transaction.vouchers.length}</span>
          </li>

          <li className="ui-li-static ui-body-inherit">
            <span>Total coupons remboursé :</span>
            <span className="tw-bold">
              {" "}
              {
                transaction.vouchers.filter((v) => v.is_redeemed === true)
                  .length
              }
            </span>
          </li>

          <li className="ui-li-static ui-body-inherit">
            <span>Total coupons resté :</span>
            <span className="tw-bold">
              {" "}
              {
                transaction.vouchers.filter((v) => v.is_redeemed === false)
                  .length
              }
            </span>
          </li>

          <li className="ui-li-static ui-body-inherit">
            <span>Montant :</span>
            <span className="tw-bold">
              {" "}
              {transaction.vouchers[0]?.amount} {currency}
            </span>
          </li>

          <li className="ui-li-static ui-body-inherit">
            <span>Total montant :</span>
            <span className="tw-bold">
              {" "}
              {totalVouchersAmount(transaction.vouchers)} {currency}
            </span>
          </li>

          <li className="ui-li-static ui-body-inherit">
            <span>Total montant remboursé :</span>
            <span className="tw-bold user-blocked">
              {" "}
              {totalVouchersRedeemedAmount(transaction.vouchers)} {currency}
            </span>
          </li>

          <li className="ui-li-static ui-body-inherit">
            <span>Total montant resté :</span>
            <span className="tw-bold user-active">
              {" "}
              {totalVouchersNotRedeemedAmount(transaction.vouchers)} {currency}
            </span>
          </li>

          <li className="ui-li-static ui-body-inherit">
            <span className="tw-bold">Date de création :</span>
            <span> {formatDate2(transaction.created_at)} </span>
          </li>

          <ReactToPrint
            trigger={() => {
              return (
                <li className="ui-li-static ui-body-inherit ui-last-child">
                  <div
                    onClick={openPrintPage}
                    className="ui-btn ui-input-btn ui-btn-f ui-corner-all ui-shadow ui-mini ui-icon-bars ui-btn-icon-right"
                  >
                    Imprimer coupons
                    <input
                      type="button"
                      data-mini="true"
                      data-icon="refresh"
                      data-theme="f"
                      data-iconpos="right"
                      defaultValue="Reset password"
                    />
                  </div>
                </li>
              );
            }}
            documentTitle={`Coupons ${transaction.id}`}
            content={() => printDomRef.current}
          />

          <div style={{ display: "none" }}>
            <VouchersPrint
              ref={printDomRef}
              organization_data={organization_data}
              transaction={transaction}
            />
          </div>
        </ul>
      </div>
    </>
  );
}

const SwitchButton = React.memo(function SwitchButton({
  transaction_id,
  status,
  refetch,
  setLoader,
}) {
  const [enabled, setEnabled] = useState(status);

  const { mutate, data, isLoading, isSuccess, isError, error, reset } =
    useMutation(async (data) => api_cms.post("/vouchers/update-status", data), {
      onSuccess: (data) => {
        if (data.type === "ok") {
          refetch();
          setEnabled(!enabled);
        }
      },
    });

  React.useEffect(() => {
    setLoader(isLoading);
  });

  const changeUserStatus = () => {
    if (isLoading) return;

    let mode = enabled ? "off" : "on";
    mutate({
      transaction_id: transaction_id,
      mode: mode,
    });
  };

  return (
    <>
      <Switch
        as="div"
        checked={enabled}
        onChange={changeUserStatus}
        className={`ui-flipswitch ui-shadow-inset ui-bar-k ui-corner-all ${
          enabled ? "ui-flipswitch-active" : ""
        }`}
      >
        <a
          href="#"
          className="ui-flipswitch-on ui-btn ui-shadow ui-btn-inherit"
        >
          YES
        </a>
        <span className="ui-flipswitch-off">NO</span>
      </Switch>
    </>
  );
});
