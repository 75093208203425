// @ts-nocheck

import React, { useRef } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { client_v2, getToken } from "../../context/auth/auth-provider";
import Info from "../../components/info";
import Header from "../../components/Header";
import { useLoading } from "../../context/loading-context";

export default function CreateUser({ type }) {
  const navigate = useNavigate();

  const { loader, setLoader } = useLoading();

  const UsernameRef = useRef();
  const PasswordRef = useRef();

  const EmptyInput = (e) => {
    const input = e.current;

    input.value = "";
  };

  const Empty = (e) => {
    const input = e.target;

    input.className = "";
  };

  const { mutate, data, isLoading, isSuccess, isError, error, reset } =
    useMutation(async (data) =>
      client_v2("create-user", { data, token: await getToken() })
    );

  const redirectHome = () => {
    navigate("/");
  };

  React.useEffect(() => {
    setLoader(isLoading);
  });

  function handleSubmit(event: any) {
    if (isLoading) return;

    event.preventDefault();

    const { username, pswd } = event.target.elements;

    var go = true;
    if (username.value.length < 5) {
      UsernameRef.current.className = "input_textbox_error";
      go = false;
    }

    if (pswd.value.length < 5) {
      PasswordRef.current.className = "input_textbox_error";
      go = false;
    }

    if (go) {
      mutate({
        username: username.value,
        password: pswd.value,
        role: type.toLowerCase(),
      });
    }
  }

  if (isError) {
    return (
      <>
        <Header text={`info`}>
          <Info message={error.message} type="warning" hideInfoView={reset} />
        </Header>
      </>
    );
  } else if (isSuccess)
    return (
      <>
        <Header text={`info`}>
          <Info
            message={data.message}
            type="success"
            hideInfoView={redirectHome}
          />
        </Header>
      </>
    );
  else {
    return (
      <>
        <Header text={`${type} register - New ${type}`}>
          <div id="twMainContentView">
            <form
              autoComplete="off"
              action="admin/agency/create-agency"
              method="post"
              onSubmit={handleSubmit}
            >
              <ul
                data-role="listview"
                data-inset="false"
                className="ui-listview"
              >
                <li className="ui-li-static ui-body-inherit ui-first-child ui-last-child">
                  <ul
                    data-role="listview"
                    data-inset="false"
                    id="editForm"
                    className="ui-listview"
                  >
                    <li
                      data-role="list-divider"
                      className="tw-ui-li-divider ui-li-divider ui-bar-b ui-first-child"
                      data-theme="b"
                      role="heading"
                    >
                      Account details
                    </li>
                    <li
                      data-role="fieldcontain"
                      className="ui-field-contain ui-li-static ui-body-inherit"
                    >
                      <label className="field-hint">Username:</label>
                      <div className="ui-grid-a">
                        <div className="ui-block-a" style={{ width: "88%" }}>
                          <div className="ui-input-text ui-body-inherit ui-corner-all ui-mini ui-shadow-inset ui-focus">
                            <input
                              type="text"
                              name="username"
                              id="username"
                              placeholder="Utilisateur"
                              maxLength="16"
                              data-mini="true"
                              data-valgroup="login"
                              autoCorrect="off"
                              autoCapitalize="none"
                              autoComplete="azfazfazf"
                              data-valfunction="username"
                              ref={UsernameRef}
                              onChange={(e) => {
                                Empty(e);
                              }}
                            />
                          </div>
                        </div>
                        <div
                          className="ui-block-b"
                          style={{ width: "9%", marginTop: "4px" }}
                        >
                          <div
                            onClick={(e) => EmptyInput(UsernameRef)}
                            className="ui-btn ui-input-btn ui-btn-i ui-corner-all ui-shadow ui-btn-inline ui-icon-recycle ui-btn-icon-notext"
                          >
                            <input
                              type="button"
                              data-icon="recycle"
                              data-theme="i"
                              data-inline="true"
                              data-iconpos="notext"
                            />
                          </div>
                        </div>
                      </div>
                    </li>
                    <li
                      data-role="fieldcontain"
                      className="ui-field-contain ui-li-static ui-body-inherit"
                    >
                      <label className="field-hint">Password:</label>
                      <div className="ui-grid-a">
                        <div className="ui-block-a" style={{ width: "88%" }}>
                          <div className="ui-input-text ui-body-inherit ui-corner-all ui-mini ui-shadow-inset">
                            <input
                              type="password"
                              name="pswd"
                              id="pswd"
                              placeholder="Password"
                              maxLength="16"
                              data-mini="true"
                              autoComplete="azfazfazfx"
                              data-valgroup="login"
                              data-valfunction="pswd"
                              ref={PasswordRef}
                              onChange={(e) => {
                                Empty(e);
                              }}
                            />
                          </div>
                        </div>
                        <div
                          className="ui-block-b"
                          style={{ width: "9%", marginTop: "4px" }}
                        >
                          <div
                            onClick={(e) => EmptyInput(PasswordRef)}
                            className="ui-btn ui-input-btn ui-btn-i ui-corner-all ui-shadow ui-btn-inline ui-icon-recycle ui-btn-icon-notext"
                          >
                            <input
                              type="button"
                              data-icon="recycle"
                              data-theme="i"
                              data-inline="true"
                              data-iconpos="notext"
                            />
                          </div>
                        </div>
                      </div>
                    </li>
                    <li
                      data-role="fieldcontain"
                      className="ui-field-contain ui-li-static ui-body-inherit ui-last-child"
                    >
                      <div className="ui-grid-solo">
                        <div className="ui-btn ui-input-btn ui-btn-c ui-corner-all ui-shadow ui-mini ui-icon-check ui-btn-icon-right">
                          Create account
                          <input
                            type="submit"
                            value="Create account"
                            data-mini="true"
                            data-icon="check"
                            data-theme="c"
                            data-iconpos="right"
                          />
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </form>
          </div>
        </Header>
      </>
    );
  }
}
