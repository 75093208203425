// @ts-nocheck
import React, { useState } from "react";
import DatePicker from "./DatePicker";
import { useLoading } from "../../context/loading-context";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  EndDateState,
  StartDateState,
  ToggleDateState,
  numberFormat,
  formatDateTransaction,
} from "../../utils/date-utils";

import Header from "../../components/Header";
import { useQuery, useMutation } from "react-query";
import { api_cms } from "../../context/auth/auth-provider";

const goToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

export default function TransactionHistory() {
  const { loader, setLoader } = useLoading();

  const [trData, setTrData] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const [whichReq, setWhichReq] = useState(1);

  const {
    data: queryData,
    isLoading: queryIsLoading,
    isError: queryIsError,
    error: queryError,
  } = useQuery(
    `transaction-history`,
    async (queryData) => api_cms.post(`transactions-history`),
    {
      onSuccess: (queryData) => {
        setTrData(queryData.data);
      },
    }
  );

  const {
    mutate,
    data: mutationData,
    isLoading: mutationIsLoading,
    mutationError,
    reset,
  } = useMutation(async (data) => api_cms.post("transactions-history", data), {
    onSuccess: (mutationData) => {
      setTrData(mutationData.data);
    },
  });

  const searchItems = (searchValue) => {
    setSearchInput(searchValue);
    if (searchInput !== "") {
      const filteredData = trData.filter((item) => {
        return Object.values(item.recipient)
          .join("")
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      });
      setFilteredResults(filteredData);
    } else {
      setFilteredResults(trData);
    }
  };

  React.useEffect(() => {
    if (whichReq === 1) {
      setLoader(queryIsLoading);
    } else {
      setLoader(mutationIsLoading);
    }
  });

  const submitTransaction = () => {
    if (mutationIsLoading) return;
    setWhichReq(2);
    mutate({
      sdate: StartDate,
      edate: EndDate,
    });
  };

  const [toggleDate, setToggleDate] = useRecoilState(ToggleDateState);
  const StartDate = useRecoilValue(StartDateState);
  const EndDate = useRecoilValue(EndDateState);

  const [togglePeriod, setTogglePeriod] = useState(true);

  const toggleFnDate = (period) => {
    setToggleDate(!toggleDate);
    period ? setTogglePeriod(true) : setTogglePeriod(false);
  };

  return (
    <>
      {toggleDate && <DatePicker dateOption={togglePeriod} />}
      <Header text={`Partners - Transaction history`}>
        <div id="twMainContentView">
          <ul data-role="listview" data-inset="false" className="ui-listview">
            <li
              data-role="list-divider"
              className="tw-ui-li-divider ui-li-divider ui-bar-b ui-first-child ui-last-child"
              data-theme="b"
              role="heading"
            >
              Transaction history
            </li>
          </ul>
          <ul
            data-role="listview"
            data-inset="false"
            style={{ paddingTop: "17px" }}
            className="ui-listview"
          >
            <li className="ui-field-contain ui-li-static ui-body-inherit ui-first-child">
              <label htmlFor="fdate" style={{ fontSize: "14px" }}>
                Start date
              </label>
              <div className="ui-input-text ui-body-inherit ui-corner-all ui-mini ui-shadow-inset ui-input-has-clear">
                <input
                  type="text"
                  name="fdate"
                  id="fdate"
                  data-role="datebox"
                  data-mini="true"
                  value={StartDate}
                  min="2021-10-27"
                  data-valgroup="dFilterForms"
                  data-valfunction="dateValid"
                  readOnly="readonly"
                  onFocus={() => toggleFnDate(true)}
                />
                <a
                  className="ui-input-clear ui-btn ui-icon-calendar ui-btn-icon-notext ui-corner-all"
                  title="Open Date Picker"
                  onClick={() => toggleFnDate(true)}
                >
                  Open Date Picker
                </a>
              </div>
            </li>
            <li className="ui-field-contain ui-li-static ui-body-inherit">
              <label htmlFor="edate" style={{ fontSize: "14px" }}>
                Ending date
              </label>
              <div className="ui-input-text ui-body-inherit ui-corner-all ui-mini ui-shadow-inset ui-input-has-clear">
                <input
                  type="text"
                  name="edate"
                  id="edate"
                  data-role="datebox"
                  data-mini="true"
                  value={EndDate}
                  min="2021-10-27"
                  data-valgroup="dFilterForms"
                  data-valfunction="dateValid"
                  readOnly="readonly"
                  onFocus={() => toggleFnDate(false)}
                />
                <a
                  className="ui-input-clear ui-btn ui-icon-calendar ui-btn-icon-notext ui-corner-all"
                  title="Open Date Picker"
                  onClick={() => toggleFnDate(false)}
                >
                  Open Date Picker
                </a>
              </div>
            </li>
            <li className="ui-field-contain ui-li-static ui-body-inherit ui-last-child">
              <div
                onClick={submitTransaction}
                className="ui-btn ui-input-btn ui-btn-c ui-corner-all ui-shadow ui-mini ui-icon-search ui-btn-icon-right"
              >
                Display
                <input
                  type="button"
                  value="Display"
                  data-icon="search"
                  data-theme="c"
                  data-iconpos="right"
                  data-mini="true"
                />
              </div>
            </li>
          </ul>
          <div className="tw-ul-overview" style={{ marginTop: "20px" }}>
            <div>
              <div className="ui-input-search ui-body-inherit ui-corner-all ui-mini ui-shadow-inset ui-input-has-clear">
                <input
                  id="searchCardUserAuto"
                  data-mini="true"
                  data-type="search"
                  placeholder="Search ..."
                  value={searchInput}
                  onChange={(e) => searchItems(e.target.value)}
                  maxLength="30"
                />
                <a
                  href={void 0}
                  onClick={() => setSearchInput("")}
                  tabIndex="-1"
                  aria-hidden="true"
                  className={`ui-input-clear ui-btn ui-icon-delete ui-btn-icon-notext ui-corner-all ${
                    searchInput.length < 1 && "ui-input-clear-hidden"
                  }`}
                  title="Clear text"
                >
                  Clear text
                </a>
              </div>
            </div>
            <ul
              className="ul-simple transactional-list ui-group-theme-a ui-listview"
              data-role="listview"
              data-filter="true"
              data-input="#searchTransactions"
              data-theme="a"
              data-count-theme="a"
              data-inset="false"
              style={{ marginTop: "12px" }}
            >
              <li
                data-role="list-divider"
                className="tw-ui-li-divider ui-li-divider ui-bar-b ui-bar-a ui-first-child"
                role="heading"
              >
                <div className="ui-grid-c tw-fsize10">
                  <div className="ui-block-a tw-inline">Utilisateur</div>
                  <div className="ui-block-b tw-inline tw-text-center">
                    Date
                  </div>
                  <div className="ui-block-c tw-inline tw-text-rintent10">
                    Amount
                  </div>
                  <div className="ui-block-d tw-inline tw-text-rintent10">
                    Balance
                  </div>
                </div>
              </li>
              {searchInput.length > 1
                ? filteredResults.map((tr: any, index: any) => (
                    <li
                      key={index}
                      className="pt-row ui-li-static ui-body-inherit"
                    >
                      <div className="ui-grid-c tw-fsize11">
                        <div className="ui-block-a tw-inline">
                          {tr.recipient.username}
                        </div>
                        <div className="ui-block-b tw-inline tw-text-center">
                          {formatDateTransaction(tr.created_at)}
                        </div>
                        <div
                          className={`ui-block-c tw-inline tw-text-right tw-break-word user-${
                            tr.type === "deposit" ? "active" : "inactive"
                          }`}
                        >
                          {tr.type === "withdrawal" && "-"}
                          {numberFormat(tr.amount, 2)}
                        </div>
                        <div className="ui-block-d tw-inline tw-text-right tw-break-word">
                          {numberFormat(tr.recipient_balance_after, 2)}
                        </div>
                      </div>
                    </li>
                  ))
                : trData &&
                  trData.map((tr: any, index: any) => (
                    <li
                      key={index}
                      className="pt-row ui-li-static ui-body-inherit"
                    >
                      <div className="ui-grid-c tw-fsize11">
                        <div className="ui-block-a tw-inline">
                          {tr.recipient.username}
                        </div>
                        <div className="ui-block-b tw-inline tw-text-center">
                          {formatDateTransaction(tr.created_at)}
                        </div>
                        <div
                          className={`ui-block-c tw-inline tw-text-right tw-break-word user-${
                            tr.type === "deposit" ? "active" : "inactive"
                          }`}
                        >
                          {tr.type === "withdrawal" && "-"}
                          {numberFormat(tr.amount, 2)}
                        </div>
                        <div className="ui-block-d tw-inline tw-text-right tw-break-word">
                          {numberFormat(tr.recipient_balance_after, 2)}
                        </div>
                      </div>
                    </li>
                  ))}

              <li className="ui-li-static ui-body-inherit ui-last-child">
                <div
                  onClick={goToTop}
                  className="ui-btn ui-input-btn ui-btn-e ui-corner-all ui-shadow ui-mini ui-icon-arrow-u ui-btn-icon-right"
                >
                  Top
                  <input
                    type="button"
                    data-theme="e"
                    value="Top"
                    data-icon="arrow-u"
                    data-iconpos="right"
                    data-mini="true"
                  />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </Header>
    </>
  );
}
