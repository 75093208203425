// @ts-nocheck

import React from "react";
import { useLoading } from "../context/loading-context";

interface Props {
	message: string;
	setIsSuccess: any;
	setIsOk: any;
}

const PopupInfo: React.FC<Props> = ({
	message,
	type,
	setIsSuccess,
	setIsOk,
	isOk = false,
}) => {
	const { loader, setLoader } = useLoading();

	React.useEffect(() => {
		setLoader(false);
	}, []);
	const hideThis = () => {
		setIsSuccess({ state: false, message: null, type: null });

		if (isOk) {
			setIsOk(false);
		}
	};

	if (type === "info") {
		const CustomMsg = () => {
			if (isOk) {
				let msg_array = message.split("|");

				return <>{message}</>;
			}

			return message;
		};

		let classInfo =
			isOk === true
				? "tw-info tw-msg-check-circle2"
				: "tw-info tw-msg-warning3";

		return (
			<div id="twInfoContentView">
				<div>
					<ul
						className="ui-listview ui-listview-inset ui-corner-all ui-shadow"
						id="twInfoView"
						data-role="listview"
						data-inset="true"
					>
						<li
							className="ui-li-divider ui-bar-inherit ui-first-child"
							data-role="list-divider"
							role="heading"
						>
							Info
						</li>
						<li className="ui-li-static ui-body-inherit">
							<div className={`${classInfo}`} />
							<div className="tw-popup-box">
								<CustomMsg />
							</div>
						</li>
						<li className="ui-li-static ui-body-inherit ui-last-child">
							<div className="ui-btn ui-input-btn ui-btn-e ui-corner-all ui-shadow ui-mini ui-icon-check ui-btn-icon-right">
								OK
								<input
									type="button"
									data-iconpos="right"
									data-icon="check"
									data-mini="true"
									onClick={hideThis}
									defaultValue="OK"
								/>
							</div>
						</li>
					</ul>
				</div>
			</div>
		);
	}

	return (
		<>
			<div>
				<div className="ui-popup-screen ui-overlay-b in" />
				<div
					className="ui-popup-container ui-popup-active"
					style={{
						maxWidth: "517px",
						position: "absolute",
						top: "calc(50% - 150px)",
						left: "calc(50% - 150px)",
					}}
				>
					<div
						data-role="popup"
						style={{ maxWidth: "-1px" }}
						data-theme="a"
						data-overlay-theme="b"
						className="ui-popup ui-body-a ui-overlay-shadow ui-corner-all"
					>
						<ul
							data-role="listview"
							data-inset="true"
							className="ui-listview ui-listview-inset ui-corner-all ui-shadow"
						>
							<li
								data-role="list-divider"
								data-theme="b"
								style={{ textIndent: "1em" }}
								role="heading"
								className="ui-li-divider ui-bar-b ui-first-child"
							>
								Info
							</li>
							<li className="ui-li-static ui-body-inherit">
								<div className="tw-info tw-msg-check-circle2" />
								<div className="tw-popup-box">{message}</div>
							</li>
							<li className="ui-li-static ui-body-inherit ui-last-child">
								<div className="ui-grid-solo">
									<div className="ui-block-a">
										<div
											onClick={hideThis}
											className="ui-btn ui-input-btn ui-btn-c ui-corner-all ui-shadow ui-mini ui-icon-check ui-btn-icon-right"
										>
											OK
											<input
												type="button"
												data-icon="check"
												data-iconpos="right"
												data-theme="c"
												defaultValue="OK"
												data-mini="true"
												className="admin-popup-close"
											/>
										</div>
									</div>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</>
	);
};

export default PopupInfo;
