// @ts-nocheck

import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Switch } from "@headlessui/react";
import { formatDate } from "../../utils/date-utils";
import { useMutation } from "react-query";
import { api_cms } from "../../context/auth/auth-provider";
import Spinner from "../../components/spinner";

export default function Details({
  refetch,
  user,
  setIsSuccess,
  setIsOK,
  setLoader,
}) {
  const { mutate, data, isLoading, isSuccess, isError, error, reset } =
    useMutation(async (data) => api_cms.post("change-password", data), {
      onSuccess: (data) => {
        if (data.type === "ok") {
          setIsOK(true);
          setIsSuccess({ state: true, message: data.message, type: "info" });
        }
      },
    });

  React.useEffect(() => {
    setLoader(isLoading);
  }, [isLoading, refetch, setLoader]);

  const changePassword = () => {
    if (isLoading) return;

    mutate({
      user_id: parseInt(user.id),
    });
  };

  return (
    <>
      <div
        id="playerDetails"
        aria-labelledby="ui-id-20"
        className="ui-tabs-panel ui-widget-content ui-corner-bottom"
        role="tabpanel"
        aria-expanded="true"
        aria-hidden="false"
        style={{ display: "block" }}
      >
        <ul
          data-role="listview"
          style={{ marginTop: "8px" }}
          data-inset="true"
          className="ul-simple ui-listview ui-listview-inset ui-corner-all ui-shadow"
        >
          <li className="ui-li-static ui-body-inherit ui-first-child">
            <span className="flex justify-start py-1 space-x-1 h-8">
              {" "}
              <span className="tw-bold">Active : </span>{" "}
              <SwitchButton
                user_id={user.id}
                status={user.status}
                refetch={refetch}
                setLoader={setLoader}
              />
            </span>
          </li>
          <li className="ui-li-static ui-body-inherit">
            <span className="tw-bold">Role:</span>
            <span> {user.role} </span>
          </li>
          <li className="ui-li-static ui-body-inherit">
            <span className="tw-bold">Agent ID:</span>
            <span> {user.id} </span>
          </li>
          <li className="ui-li-static ui-body-inherit">
            <span className="tw-bold">Creation Date:</span>
            <span> {formatDate(user.created_at)} </span>
          </li>
          <li className="ui-li-static ui-body-inherit">
            <span className="tw-bold">Username:</span>
            <span> {user.username} </span>
          </li>
          <li className="ui-li-static ui-body-inherit ui-last-child">
            <div className="ui-btn ui-input-btn ui-btn-f ui-corner-all ui-shadow ui-mini ui-icon-refresh ui-btn-icon-right">
              Reset password
              <input
                type="button"
                onClick={changePassword}
                data-mini="true"
                data-icon="refresh"
                data-theme="f"
                data-iconpos="right"
                defaultValue="Reset password"
              />
            </div>
          </li>
        </ul>
      </div>
    </>
  );
}

const SwitchButton = React.memo(function SwitchButton({
  user_id,
  status,
  refetch,
  setLoader,
}) {
  const [enabled, setEnabled] = useState(status);

  const { mutate, data, isLoading, isSuccess, isError, error, reset } =
    useMutation(async (data) => api_cms.post("user-status", data), {
      onSuccess: (data) => {
        if (data.type === "ok") {
          refetch();
          setEnabled(!enabled);
        }
      },
    });

  React.useEffect(() => {
    setLoader(isLoading);
  });

  const changeUserStatus = () => {
    if (isLoading) return;

    let mode = enabled ? "off" : "on";
    mutate({
      user_id: parseInt(user_id),
      mode: mode,
    });
  };

  return (
    <>
      <Switch
        as="div"
        checked={enabled}
        onChange={changeUserStatus}
        className={`ui-flipswitch ui-shadow-inset ui-bar-k ui-corner-all ${
          enabled ? "ui-flipswitch-active" : ""
        }`}
      >
        <a
          href="#"
          className="ui-flipswitch-on ui-btn ui-shadow ui-btn-inherit"
        >
          YES
        </a>
        <span className="ui-flipswitch-off">NO</span>
      </Switch>
    </>
  );
});
