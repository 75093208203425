// @ts-nocheck

import React from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { numberFormat, formatDate, formatDate2 } from "../../utils/date-utils";
import Barcode from "react-barcode";
import { getReferenceId } from "../../utils/orgs-utils";

import "./style.css";

const VouchersPrint = React.forwardRef(
  ({ transaction, organization_data }, ref) => {
    let vouchers = transaction.vouchers;
    return (
      <div ref={ref} style={{ width: "100%", height: "100%" }}>
        <div className="vouchers-container">
          {vouchers.map((voucher, index) => (
            <div className="voucher-container" key={index}>
              <svg className="svgbg">
                <defs>
                  <pattern id="Text" x="0" y="0" width=".4" height=".25">
                    <text x="10" y="20" className="vectext">
                      {organization_data?.website}
                    </text>
                  </pattern>
                </defs>

                <rect
                  fill="url(#Text)"
                  stroke="black"
                  width="100%"
                  height="100%"
                />
              </svg>

              <div className="voucher-item">
                <img
                  height={35}
                  src={`${process.env.PUBLIC_URL}/${organization_data?.org_theme}.png`}
                />
                <p style={{ fontsize: "12pt", fontWeight: "bold" }}>
                  {" "}
                  VOUCHER DE RECHARGE
                </p>

                <span className="small-text">
                  Date : {formatDate(transaction.created_at)}
                </span>

                <span className="voucher-code">CODE : {voucher.code}</span>

                <Barcode
                  value={voucher.code}
                  displayValue={false}
                  height={60}
                  width={2}
                  margin={6}
                  textAlign="center"
                />

                <span className="voucher-code">
                  MONTANT : {numberFormat(voucher.amount)}{" "}
                  {organization_data.currency}
                </span>

                <span className="small-text">
                  Expire Le : {formatDate(voucher.expire_at)}
                </span>

                <span className="small-text">
                  N° Serie : {getReferenceId(transaction.id)}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
);

export { VouchersPrint };
