// @ts-nocheck
import React from "react";
import Spinner from "../components/spinner";

const LoadingContext = React.createContext({
  loader: false,
  setLoader: null,
});

LoadingContext.displayName = "LoadingContext";

function Loadd() {
  return <div>loading ...... </div>;
}

export function LoadingProvider({ children }) {
  const [loader, setLoader] = React.useState(false);
  const value = { loader, setLoader };

  return (
    <LoadingContext.Provider value={value}>
      {children}
      {value.loader ? <Spinner isLoading={loader} /> : ""}
    </LoadingContext.Provider>
  );
}

export function useLoading() {
  const context = React.useContext(LoadingContext);
  if (!context) {
    throw new Error("useLoading must be used within LoadingProvider");
  }
  return context;
}
