// @ts-nocheck

import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  EndDateState,
  StartDateState,
  ToggleDateState,
} from "../../utils/date-utils";
import { useRecoilState } from "recoil";

export default function Numbers(props) {
  const { Days, today, month, year, dateOption } = props;
  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }
  let monthNumberFromString = (str) => {
    return moment().month(str).format("MM");
  };
  function formatDate(date) {
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join("/");
  }
  const [toggleDate, setToggleDate] = useRecoilState(ToggleDateState);
  const [StartDate, setStartDate] = useRecoilState(StartDateState);
  const [EndDate, setEndDate] = useRecoilState(EndDateState);

  const setFnDate = (today, day, month, year) => {
    console.log(dateOption);

    day = day < 10 ? `0${day}` : day;
    month = monthNumberFromString(month);

    dateOption
      ? setStartDate(day + "." + month + "." + year)
      : setEndDate(day + "." + month + "." + year);
    setToggleDate(false);
  };

  const help = formatDate(new Date());

  return (
    <div
      className="ui-datebox-gridrow"
      style={{ paddingBottom: "10px", paddingLeft: "5px" }}
    >
      {Days.map((day, key) => (
        <div
          key={key}
          onClick={() => setFnDate(today, day, month, year)}
          className={
            (today === day &&
              `ui-datebox-griddate ui-corner-all ui-btn ui-btn-b`) ||
            (new Date(day + "/" + month + "/" + year) < new Date() &&
              `ui-datebox-griddate ui-corner-all ui-btn ui-btn-a`) ||
            (new Date(day + "/" + month + "/" + year) > new Date() &&
              `ui-datebox-griddate ui-corner-all ui-btn ui-btn-a ui-state-disabled`)
          }
        >
          {day}
        </div>
      ))}
    </div>
  );
}
