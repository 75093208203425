// @ts-nocheck
import React from "react";
import { queryCache } from "react-query";
import * as auth from "./auth-provider";
import { client, client_v2 } from "./auth-provider";
import { useAsync } from "../../hooks/hooks";
import { isEmpty } from "../../utils/orgs-utils";

import Spinner from "../../components/spinner";
import FullPageSpinner from "../../components/full-page-spinner";

async function bootstrapAppData(organization) {
  let user = null;

  const stored_organization = await auth.getOrganization();

  if (isEmpty(stored_organization)) {
    await auth.setOrganization(organization);
  }

  const token = await auth.getToken();
  if (token) {
    const data = await auth.api_cms.get("get-me");
    if (data.status === false) {
      auth.logout();
      auth.clearToken();
    } else {
      user = { ...data.user, token };
    }
  }
  return user;
}

async function bootstrapOrganizationData(organization) {
  const stored_organization = await auth.getOrganization();

  if (isEmpty(stored_organization)) {
    await auth.setOrganization(organization);
  }

  return stored_organization;
}

const AuthContext = React.createContext();
AuthContext.displayName = "AuthContext";

function AuthProvider(props) {
  let organization = props.organization;

  const {
    data: user,
    status,
    error,
    isLoading,
    isIdle,
    isError,
    isSuccess,
    run,
    setData,
  } = useAsync();

  React.useEffect(() => {
    const appDataPromise = bootstrapAppData(organization);
    run(appDataPromise);
  }, [run]);

  const {
    data: organization_data,
    run: runOrganization,
    setData: setDataOrganization,
  } = useAsync();

  React.useEffect(() => {
    const orgPromise = auth.getOrganization();
    runOrganization(orgPromise);
  }, [runOrganization]);

  const login = React.useCallback(
    (form) =>
      auth.login(form).then((data) => {
        setData(data.user);
      }),
    [setData]
  );

  const refreshUserSimple = React.useCallback(() => {
    bootstrapAppData().then((data) => {
      setData(data);
    });
  }, [setData]);

  const refreshUser = React.useCallback(() => run(bootstrapAppData()), [run]);

  const logout = React.useCallback(() => {
    auth.logout();
    setData(null);
  }, [setData]);

  const value = React.useMemo(
    () => ({
      user,
      login,
      logout,
      refreshUser,
      refreshUserSimple,
      organization_data,
    }),
    [login, logout, refreshUser, refreshUserSimple, user, organization_data]
  );
  if (isLoading || isIdle) {
    return <FullPageSpinner />;
  }

  return <AuthContext.Provider value={value} {...props} />;
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
}

function useClient() {
  const { user } = useAuth();
  const token = user?.token;
  return React.useCallback(
    (endpoint, config) => client(endpoint, { ...config, token }),
    [token]
  );
}

export { AuthProvider, useAuth, useClient };
