// @ts-nocheck

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/auth/auth-context";
import { capitalize } from "../../utils/orgs-utils";
export default function Cercuit() {
  const { user, organization_data } = useAuth();

  return (
    <div
      className="tw-admin-menu ui-collapsible ui-collapsible-themed-content ui-first-child"
      data-role="collapsible"
      data-collapsed="true"
      data-theme="g"
      data-collapsed-icon="carat-r"
      data-expanded-icon="carat-d"
    >
      <h3 className="ui-collapsible-heading">
        <Link
          className="ui-collapsible-heading-toggle ui-btn ui-icon-carat-r ui-btn-icon-right ui-btn-g ui-mini"
          data-mini="true"
          data-role="button"
          data-icon="arrow-r"
          data-iconpos="right"
          to="/admin-cercuit"
          role="button"
        >
          {capitalize(user.role)} cercuit
        </Link>
      </h3>
    </div>
  );
}
