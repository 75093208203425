// @ts-nocheck

import React, { useState } from "react";
import { months } from "./data";
import { getDaysInMonth, range, ToggleDateState } from "../../utils/date-utils";
import Daily from "./Daily";
import Numbers from "./Numbers";
import { useRecoilState } from "recoil";

export default function DatePicker(props) {
  /* Initialisation */
  const init = new Date().getMonth() + 1;
  const today = new Date().getDate();
  const [count, setCount] = useState(init);
  const [year, setYear] = useState(new Date().getFullYear());
  const option = props.dateOption;
  /* State Manipulation for the component toggle */

  const [toggleDate, setToggleDate] = useRecoilState(ToggleDateState);

  const toggleFnDate = () => {
    setToggleDate(false);
    console.log("its working");
  };

  /* Necessary Date Manipulation functions */
  const decYear = () => {
    setYear(year - 1);
    setCount(12);
    console.log("dec");
  };

  const accYear = () => {
    setYear(year + 1);
    setCount(1);
    console.log("acc");
  };

  const dec = () => {
    count === 1 ? decYear() : setCount(count - 1);
  };
  const acc = () => {
    count === 12 ? accYear() : setCount(count + 1);
  };
  const numberDays = getDaysInMonth(year, count);
  const Days = range(1, numberDays);

  return (
    <div>
      <div className="ui-popup-screen ui-overlay-b in"></div>
      <div
        className="ui-popup-container fade in ui-popup-active"
        style={{
          maxWidth: "517px",
          position: "absolute",
          top: "calc(50% - 300px)",
          left: "calc(50% - 150px)",
        }}
      >
        <div
          className="ui-datebox-container ui-overlay-shadow ui-corner-all fade ui-body-a ui-popup ui-body-inherit"
          style={{ zIndex: "1100" }}
        >
          <a
            className="ui-btn-left ui-link ui-btn ui-btn-a ui-icon-delete ui-btn-icon-notext ui-shadow ui-corner-all"
            onClick={toggleFnDate}
          >
            Close
          </a>
          <div
            className="ui-header ui-bar-a"
            style={{ backgroundColor: "#333" }}
          >
            <h1 className="ui-title"> {option ? "Start" : "End"} date</h1>
          </div>
          <span>
            <div className="ui-datebox-gridheader">
              <div className="ui-datebox-gridminus" onClick={() => dec()}>
                <a className="ui-btn-inline ui-link ui-btn ui-btn-a ui-icon-minus ui-btn-icon-notext ui-shadow ui-corner-all">
                  Previous Month
                </a>
              </div>
              <div className="ui-datebox-gridplus" onClick={() => acc()}>
                <a className="ui-btn-inline ui-link ui-btn ui-btn-a ui-icon-plus ui-btn-icon-notext ui-shadow ui-corner-all">
                  Next Month
                </a>
              </div>
              <div className="ui-datebox-gridlabel">
                <h4>
                  {months[count]} {year}
                </h4>
              </div>
            </div>
            <div className="ui-datebox-grid">
              <Daily />
              <Numbers
                Days={Days}
                today={today}
                month={months[count]}
                year={year}
                dateOption={option}
              />
            </div>
          </span>
        </div>
      </div>
    </div>
  );
}
