// @ts-nocheck

import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function Vouchers() {
  const [toggle, setToggle] = useState(false);
  const upDown = () => {
    setToggle(!toggle);
  };
  return (
    <div
      className="tw-admin-menu ui-collapsible ui-collapsible-themed-content ui-first-child"
      data-role="collapsible"
      data-collapsed="true"
      data-theme="g"
      data-collapsed-icon="carat-r"
      data-expanded-icon="carat-d"
    >
      <h3 className="ui-collapsible-heading" onClick={() => upDown()}>
        <a
          href={void 0}
          className={
            toggle
              ? "ui-collapsible-heading-toggle ui-btn ui-btn-icon-right ui-btn-g ui-mini ui-icon-carat-d"
              : "ui-collapsible-heading-toggle ui-btn ui-icon-carat-r ui-btn-icon-right ui-btn-g ui-mini"
          }
        >
          {" "}
          <span> Vouchers </span>
          <span className="ui-collapsible-heading-status">
            {" "}
            click to collapse contents
          </span>
        </a>
      </h3>
      {toggle && (
        <div className="ui-collapsible-content ui-body-a" aria-hidden="false">
          {" "}
          <ul
            data-role="listview"
            data-inset="true"
            style={{ marginTop: "16px" }}
            className="ui-listview ui-l'istview-inset ui-corner-all ui-shadow"
          >
            {" "}
            <li className="ui-first-child">
              <Link
                className="tw-white-space-normal ui-link ui-btn ui-icon-arrow-r ui-btn-icon-right ui-shadow ui-corner-all ui-mini"
                data-mini="true"
                data-role="button"
                data-icon="arrow-r"
                data-iconpos="right"
                to="/vouchers/create"
                role="button"
              >
                New vouchers
              </Link>
            </li>{" "}
            <li className="ui-last-child">
              {" "}
              <Link
                className="tw-white-space-normal ui-link ui-btn ui-icon-arrow-r ui-btn-icon-right ui-shadow ui-corner-all ui-mini"
                data-mini="true"
                data-role="button"
                data-icon="arrow-r"
                data-iconpos="right"
                to="/vouchers/transactions"
                role="button"
              >
                Vouchers transactions
              </Link>
            </li>{" "}
          </ul>{" "}
        </div>
      )}
    </div>
  );
}
