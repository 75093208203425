// @ts-nocheck
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import { api_cms } from "../../context/auth/auth-provider";
import { useLoading } from "../../context/loading-context";
import Header from "../../components/Header";
import { numberFormat } from "../../utils/date-utils";

export default function UsersList({ type }) {
    const { loader, setLoader } = useLoading();

    const [usersData, setUsersData] = useState([]);
    const [filteredResults, setFilteredResults] = useState([]);
    const [searchInput, setSearchInput] = useState("");

    const { data, isLoading, isError, error } = useQuery(
        `users-list/${type.toLowerCase()}`,
        async (data) => api_cms.get(`users-list/${type.toLowerCase()}`),
        {
            onSuccess: (data) => {
                setUsersData(data.data);
            },
        }
    );

    const searchItems = (searchValue) => {
        setSearchInput(searchValue);
        if (searchInput !== "") {
            const filteredData = usersData.filter((item) => {
                return Object.values(item)
                    .join("")
                    .toLowerCase()
                    .includes(searchInput.toLowerCase());
            });
            setFilteredResults(filteredData);
        } else {
            setFilteredResults(usersData);
        }
    };

    React.useEffect(() => {
        setLoader(isLoading);
    });

    return (
        <>
            <Header text={`${type} register - ${type} accounts balances`}>
                <div id="twMainContentView">
                    <ul
                        data-role="listview"
                        data-inset="false"
                        className="ui-listview"
                    >
                        <li
                            data-role="list-divider"
                            className="tw-ui-li-divider ui-li-divider ui-bar-b ui-first-child ui-last-child"
                            data-theme="b"
                            role="heading"
                        >
                            {type} accounts balances
                        </li>
                    </ul>
                    <div style={{ marginTop: "16px" }}>
                        <ul
                            data-role="listview"
                            data-inset="false"
                            style={{ paddingTop: "0" }}
                            className="ui-listview"
                        >
                            <li
                                style={{ padding: "2px" }}
                                className="ui-li-static ui-body-inherit ui-first-child"
                            >
                                <div className="ui-input-search ui-body-inherit ui-corner-all ui-mini ui-shadow-inset ui-input-has-clear">
                                    <input
                                        id="searchCardUserAuto"
                                        data-mini="true"
                                        data-type="search"
                                        placeholder="Search ..."
                                        value={searchInput}
                                        onChange={(e) =>
                                            searchItems(e.target.value)
                                        }
                                        maxLength="30"
                                    />
                                    <a
                                        href={void 0}
                                        onClick={() => setSearchInput("")}
                                        tabIndex="-1"
                                        aria-hidden="true"
                                        className={`ui-input-clear ui-btn ui-icon-delete ui-btn-icon-notext ui-corner-all ${
                                            searchInput.length < 1 &&
                                            "ui-input-clear-hidden"
                                        }`}
                                        title="Clear text"
                                    >
                                        Clear text
                                    </a>
                                </div>
                            </li>
                            <li
                                style={{
                                    paddingTop: "2px",
                                    paddingBottom: "2px",
                                }}
                                className="outline-header ui-li-static ui-body-inherit"
                            >
                                <div className="ui-grid-a tw-fsize12">
                                    <div className="ui-block-a tw-inline">
                                        {type} account
                                    </div>
                                    <div className="ui-block-b tw-inline tw-text-right">
                                        Montant sur compte
                                    </div>
                                </div>
                            </li>
                            <li
                                style={{
                                    paddingTop: "2px",
                                    paddingBottom: "2px",
                                }}
                                className="ui-li-static ui-body-inherit ui-last-child"
                            >
                                <div className="ui-grid-a tw-fsize12">
                                    <div className="ui-block-a tw-inline tw-bold">
                                        {data?.count}
                                    </div>
                                    <div className="ui-block-b tw-inline tw-text-right tw-bold">
                                        {!isLoading && data
                                            ? parseFloat(
                                                  data?.sum_solde.toFixed(2)
                                              )
                                            : ""}
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <ul
                            data-role="listview"
                            data-inset="false"
                            id="searchInfo"
                            className="fieldHide ui-listview"
                            style={{ marginTop: "18px" }}
                        >
                            <li
                                className="pt-row-info ui-li-static ui-body-inherit ui-first-child"
                                style={{ padding: "8px" }}
                            >
                                <div className="tw-info tw-msg-info2"></div>
                                <div className="tw-popup-box">
                                    Card users not found
                                </div>
                            </li>
                            <li
                                style={{ padding: "8px" }}
                                className="ui-li-static ui-body-inherit ui-last-child"
                            >
                                <div className="ui-btn ui-input-btn ui-btn-g ui-corner-all ui-shadow ui-mini ui-icon-recycle ui-btn-icon-right">
                                    Fermer
                                    <input
                                        type="button"
                                        value="Fermer"
                                        data-mini="true"
                                        data-icon="recycle"
                                        data-theme="g"
                                        data-iconpos="right"
                                    />
                                </div>
                            </li>
                        </ul>
                        <ul
                            id="playersList"
                            data-role="listview"
                            className="tw-ul-overview transactional-list ui-listview"
                            data-inset="false"
                            style={{ paddingTop: "17px" }}
                        >
                            {searchInput.length > 1
                                ? filteredResults.map(
                                      (user: any, index: any) => (
                                          <li className="pt-row" key={index}>
                                              {" "}
                                              <Link
                                                  className="a-simple ui-link ui-btn ui-icon-arrow-r ui-btn-icon-right ui-shadow ui-corner-all ui-mini"
                                                  to={`management/${user.id}`}
                                                  data-role="button"
                                                  data-icon="arrow-r"
                                                  data-iconpos="right"
                                                  data-mini="true"
                                                  role="button"
                                              >
                                                  <div className="ui-grid-a">
                                                      <div
                                                          className="ui-block-a tw-break-word tw-fsize11"
                                                          style={{
                                                              width: "75%",
                                                          }}
                                                      >
                                                          <div
                                                              className={`user-${
                                                                  user.status ===
                                                                  true
                                                                      ? "active"
                                                                      : "blocked"
                                                              }`}
                                                          >
                                                              {user.username}
                                                          </div>
                                                          <div className="tw-fsize11 tw-fwn">
                                                              {type} ID :{" "}
                                                              {user.id}
                                                          </div>
                                                      </div>
                                                      <div
                                                          className="ui-block-b tw-text-right tw-fwn tw-fsize11"
                                                          style={{
                                                              width: "25%",
                                                          }}
                                                      >
                                                          {numberFormat(
                                                              user.balance,
                                                              2
                                                          )}
                                                      </div>
                                                  </div>
                                              </Link>
                                          </li>
                                      )
                                  )
                                : usersData &&
                                  usersData.map((user: any, index: any) => (
                                      <li className="pt-row" key={index}>
                                          {" "}
                                          <Link
                                              className="a-simple ui-link ui-btn ui-icon-arrow-r ui-btn-icon-right ui-shadow ui-corner-all ui-mini"
                                              to={`management/${user.id}`}
                                              data-role="button"
                                              data-icon="arrow-r"
                                              data-iconpos="right"
                                              data-mini="true"
                                              role="button"
                                          >
                                              <div className="ui-grid-a">
                                                  <div
                                                      className="ui-block-a tw-break-word tw-fsize11"
                                                      style={{ width: "75%" }}
                                                  >
                                                      <div
                                                          className={`user-${
                                                              user.status ===
                                                              true
                                                                  ? "active"
                                                                  : "blocked"
                                                          }`}
                                                      >
                                                          {user.username}
                                                      </div>
                                                      <div className="tw-fsize11 tw-fwn">
                                                          {type} ID : {user.id}
                                                      </div>
                                                  </div>
                                                  <div
                                                      className="ui-block-b tw-text-right tw-fwn tw-fsize11"
                                                      style={{ width: "25%" }}
                                                  >
                                                      {numberFormat(
                                                          user.balance,
                                                          2
                                                      )}
                                                  </div>
                                              </div>
                                          </Link>
                                      </li>
                                  ))}
                        </ul>
                    </div>
                </div>
            </Header>
        </>
    );
}
