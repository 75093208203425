// @ts-nocheck

import React, { useRef } from "react";
import { useSetRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import { AmountState, showAsFloat, numberFormat } from "../../utils/date-utils";
import Keypad from "../../components/keypad";
import Info from "../../components/info";
import { useMutation } from "react-query";
import { api_cms } from "../../context/auth/auth-provider";
import { useAuth } from "../../context/auth/auth-context";

const Payement = React.memo(function Payement({
  user,
  refetch,
  setIsSuccess,
  setLoader,
}) {
  const { refreshUserSimple } = useAuth();

  const [isKeypad, setKeypad] = React.useState(false);

  const amountRef = useRef();

  const amount = useRecoilValue(AmountState);

  const setAmountState = useSetRecoilState(AmountState);

  const handleClick = (event) => {
    setAmountState(amount + parseInt(event.target.value));
    amountRef.current.className = "";
  };

  function handleHideKeypad() {
    setKeypad(false);
  }

  function handleSetKeypad(value: any) {
    setAmountState(value);
    amountRef.current.className = "";
    handleHideKeypad();
  }

  const resetUseRecoil = () => setAmountState(0);

  const { mutate, data, isLoading, isIdle, isSuccess, isError, error, reset } =
    useMutation(async (data) => api_cms.post("user-solde", data), {
      onSuccess: (data) => {
        if (data.type === "ok") {
          refreshUserSimple();
          setIsSuccess({ state: true, message: data.message, type: "popup" });
          setAmountState(0);
          refetch();
        }
      },
      onError: (data) => {
        setAmountState(0);
        setIsSuccess({ state: true, message: data.message, type: "info" });
      },
    });

  React.useEffect(() => {
    setLoader(isLoading);
  });

  function handleSubmit(mode) {
    if (isLoading) return;

    let ready_amount = parseFloat(amount);
    let user_id = user.id;

    if (ready_amount <= 0) {
      amountRef.current.className = "input_textbox_error";
      return false;
    }

    mutate({
      user_id: parseInt(user_id),
      amount: ready_amount,
      mode: mode,
    });
  }

  return (
    <>
      <div
        id="payment"
        aria-labelledby="ui-id-16"
        className="ui-tabs-panel ui-widget-content ui-corner-bottom"
        role="tabpanel"
        aria-expanded="true"
        aria-hidden="false"
      >
        <div id="alternatingBlock" className={`${isKeypad ? "fieldHide" : ""}`}>
          <div className="ui-grid-b">
            <div className="ui-block-a">
              <button
                type="button"
                className="payment-chip ui-btn ui-shadow ui-corner-all"
                onClick={handleClick}
                value={100}
              >
                100
              </button>
            </div>
            <div className="ui-block-b">
              <button
                type="button"
                className="payment-chip ui-btn ui-shadow ui-corner-all"
                onClick={handleClick}
                value={200}
              >
                200
              </button>
            </div>
            <div className="ui-block-c">
              <button
                type="button"
                className="payment-chip ui-btn ui-shadow ui-corner-all"
                onClick={handleClick}
                value={300}
              >
                300
              </button>
            </div>
            <div className="ui-block-a">
              <button
                type="button"
                className="payment-chip ui-btn ui-shadow ui-corner-all"
                onClick={handleClick}
                value={500}
              >
                500
              </button>
            </div>
            <div className="ui-block-b">
              <button
                type="button"
                className="payment-chip ui-btn ui-shadow ui-corner-all"
                onClick={handleClick}
                value={1000}
              >
                1000
              </button>
            </div>
            <div className="ui-block-c">
              <button
                type="button"
                className="payment-chip ui-btn ui-shadow ui-corner-all"
                onClick={handleClick}
                value={2000}
              >
                2000
              </button>
            </div>
            <div className="ui-block-a">
              <button
                type="button"
                className="payment-chip ui-btn ui-shadow ui-corner-all"
                onClick={handleClick}
                value={3000}
              >
                3000
              </button>
            </div>
            <div className="ui-block-b">
              <button
                type="button"
                className="payment-chip ui-btn ui-shadow ui-corner-all"
                onClick={handleClick}
                value={4000}
              >
                4000
              </button>
            </div>
            <div className="ui-block-c">
              <button
                type="button"
                className="payment-chip ui-btn ui-shadow ui-corner-all"
                onClick={handleClick}
                value={5000}
              >
                5000
              </button>
            </div>
          </div>
          <div className="ui-field-contain">
            <label style={{ fontSize: "14px" }} htmlFor="amount">
              Amount:
            </label>
            <div className="ui-input-text ui-body-inherit ui-corner-all ui-mini ui-shadow-inset ui-input-has-clear">
              <input
                type="text"
                id="amount"
                name="amount"
                className="amount-block"
                data-mini="true"
                placeholder="Amount"
                data-clear-btn="true"
                maxLength={16}
                data-valgroup="payment"
                data-valfunction="amount"
                ref={amountRef}
                onFocus={() => setKeypad(true)}
                readOnly
                value={amount === 0 ? showAsFloat(amount, 2) : amount}
              />
              <a
                tabIndex={-1}
                aria-hidden="true"
                className="ui-input-clear ui-btn ui-icon-delete ui-btn-icon-notext ui-corner-all"
                title="Clear text"
                onClick={resetUseRecoil}
              >
                Clear text
              </a>
            </div>
          </div>
          <div className="ui-field-contain">
            <div className="ui-btn ui-input-btn ui-btn-c ui-corner-all ui-shadow ui-mini ui-icon-arrow-d ui-btn-icon-right">
              Deposit
              <input
                type="button"
                data-mini="true"
                defaultValue="Deposit"
                data-theme="c"
                data-icon="arrow-d"
                data-iconpos="right"
                onClick={() => handleSubmit("deposit")}
                data-transfermode={1}
              />
            </div>
          </div>
          <div className="ui-field-contain">
            <div className="ui-btn ui-input-btn ui-btn-f ui-corner-all ui-shadow ui-mini ui-icon-arrow-u ui-btn-icon-right">
              Retrait
              <input
                type="button"
                data-mini="true"
                defaultValue="Retrait"
                data-theme="f"
                data-icon="arrow-u"
                data-iconpos="right"
                onClick={() => handleSubmit("retrait")}
                data-transfermode={2}
              />
            </div>
          </div>
        </div>

        {isKeypad && (
          <Keypad
            initialValue={numberFormat(0, 2)}
            handleSetKeypad={handleSetKeypad}
            handleHideKeypad={handleHideKeypad}
          />
        )}
      </div>
    </>
  );
});

export default Payement;
