// @ts-nocheck

import React, { useState } from "react";
import { Link, useParams, useLocation, Outlet } from "react-router-dom";
import { Tab } from "@headlessui/react";
import Header from "../../components/Header";
import Details from "./transaction-details";
import VouchersList from "./vouchers-list";
import PopupInfo from "../../components/popup-info";
import { numberFormat } from "../../utils/date-utils";
import { getReferenceId } from "../../utils/orgs-utils";
import { useQuery } from "react-query";
import { api_cms } from "../../context/auth/auth-provider";
import { useLoading } from "../../context/loading-context";
import { useAuth } from "../../context/auth/auth-context";

export default function VouchersManagement() {
  const { organization_data } = useAuth();

  const { loader, setLoader } = useLoading();

  const [isSuccess, setIsSuccess] = useState({
    state: false,
    message: null,
    type: null,
  });

  const [isInfo, setIsInfo] = useState(false);

  const [isOK, setIsOK] = useState(false);

  const [active, setActive] = useState("1");

  const handleClick = (event) => {
    setActive(event.target.id);
  };

  const { trId }: { trId: string } = useParams();

  const { data, isLoading, isError, error, refetch } = useQuery(
    `vouchers/${trId}`,
    async (data) => {
      return api_cms.get(`vouchers/${trId}`);
    },
    {
      select: (data) => {
        return data.data;
      },
    }
  );

  React.useEffect(() => {
    setLoader(isLoading);
  }, [setLoader, isLoading]);

  const handleFocus = (event) => {
    let target = event.target.classList;

    target.remove("ui-state-focus");
    target.add("ui-state-focus");
  };

  if (isLoading) {
    return (
      <>
        <Header text={`Coupons management`}></Header>
      </>
    );
  }

  if (isSuccess.state && isSuccess.type === "info") {
    return (
      <>
        <Header text={`info`}>
          <PopupInfo
            message={isSuccess.message}
            type="info"
            setIsOk={setIsOK}
            setIsSuccess={setIsSuccess}
            isOk={isOK}
          />
        </Header>
      </>
    );
  }

  return (
    <>
      <Header
        text={`Coupons management`}
        isSuccessConfig={isSuccess}
        setIsSuccess={setIsSuccess}
      >
        <div id="twMainContentView">
          <div className="ui-grid-a grid-medium">
            <div className="ui-block-a">
              <span>N° Serie: </span>
              <span
                className={`tw-bold account-holder  user-${
                  data?.status === true ? "active" : "blocked"
                }`}
              >
                {getReferenceId(data?.id)}
              </span>
            </div>
          </div>
          <div
            data-role="tabs"
            id="playerDetailsNav"
            style={{ marginTop: "4px" }}
            className="ui-tabs ui-widget ui-widget-content ui-corner-all"
          >
            <Tab.Group as="div" className="ui-navbar">
              <Tab.List
                as="ul"
                className="ui-grid-b ui-tabs-nav ui-helper-reset ui-helper-clearfix ui-widget-header ui-corner-all"
                style={{ display: "flex", alignItems: "baseline" }}
              >
                <Tab
                  as="li"
                  tabIndex={-1}
                  role="tab"
                  aria-controls="payment"
                  style={{ outline: "none", width: "100%" }}
                  onFocus={(e) => handleFocus(e)}
                >
                  {({ selected }) => (
                    <div
                      tabIndex={-1}
                      className={`ui-state-persist ui-link ui-btn ui-tabs-anchor ${
                        selected ? "ui-btn-active" : ""
                      }`}
                      style={{ outline: "none" }}
                      role="presentation"
                    >
                      Details
                    </div>
                  )}
                </Tab>
                <Tab
                  as="li"
                  tabIndex={-1}
                  role="tab"
                  aria-controls="payment"
                  style={{ outline: "none", width: "100%" }}
                  onFocus={(e) => handleFocus(e)}
                >
                  {({ selected }) => (
                    <div
                      tabIndex={-1}
                      className={`ui-state-persist ui-link ui-btn ui-tabs-anchor ${
                        selected ? "ui-btn-active" : ""
                      }`}
                      style={{ outline: "none" }}
                      role="presentation"
                    >
                      Coupons
                    </div>
                  )}
                </Tab>
              </Tab.List>

              <Tab.Panels>
                <Tab.Panel>
                  <Details
                    refetch={refetch}
                    transaction={data}
                    setIsOK={setIsOK}
                    setIsSuccess={setIsSuccess}
                    setLoader={setLoader}
                    organization_data={organization_data}
                  />
                </Tab.Panel>
                <Tab.Panel>
                  <VouchersList
                    transaction={data}
                    organization_data={organization_data}
                  />
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </div>
        </div>
      </Header>
    </>
  );
}
