// @ts-nocheck

import React from "react";

export default function Daily() {
  return (
    <div className="ui-datebox-gridrow">
      <div className="ui-datebox-griddate ui-datebox-griddate-label">Su</div>
      <div className="ui-datebox-griddate ui-datebox-griddate-label">Mo</div>
      <div className="ui-datebox-griddate ui-datebox-griddate-label">Tu</div>
      <div className="ui-datebox-griddate ui-datebox-griddate-label">We</div>
      <div className="ui-datebox-griddate ui-datebox-griddate-label">Th</div>
      <div className="ui-datebox-griddate ui-datebox-griddate-label">Fr</div>
      <div className="ui-datebox-griddate ui-datebox-griddate-label">Sa</div>
    </div>
  );
}
