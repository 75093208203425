// @ts-nocheck
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import { api_cms } from "../../context/auth/auth-provider";
import { useLoading } from "../../context/loading-context";
import Header from "../../components/Header";
import { formatDate2 } from "../../utils/date-utils";
import { getReferenceId } from "../../utils/orgs-utils";

export default function VouchersTransactions() {
    const { loader, setLoader } = useLoading();

    const {
        data: transactions,
        isLoading,
        isError,
        error,
    } = useQuery(`vouchers/transactions`, async (data) =>
        api_cms.get(`vouchers/transactions`)
    );

    React.useEffect(() => {
        setLoader(isLoading);
    });

    return (
        <>
            <Header text={`Vouchers register - Vouchers transactions`}>
                <div id="twMainContentView">
                    <div style={{ marginTop: "16px" }}>
                        <ul
                            data-role="listview"
                            data-inset="false"
                            style={{ paddingTop: "0" }}
                            className="ui-listview"
                        >
                            <li
                                style={{
                                    paddingTop: "2px",
                                    paddingBottom: "2px",
                                }}
                                className="outline-header ui-li-static ui-body-inherit"
                            >
                                <div className="ui-grid-a tw-fsize12">
                                    <div className="ui-block-a tw-inline">
                                        N° Serie
                                    </div>
                                    <div className="ui-block-b tw-inline tw-text-right">
                                        Total vouchers
                                    </div>
                                </div>
                            </li>
                            <li
                                style={{
                                    paddingTop: "2px",
                                    paddingBottom: "2px",
                                }}
                                className="ui-li-static ui-body-inherit ui-last-child"
                            >
                                <div className="ui-grid-a tw-fsize12">
                                    <div className="ui-block-a tw-inline tw-bold">
                                        {transactions?.data?.count}
                                    </div>
                                    <div className="ui-block-b tw-inline tw-text-right tw-bold">
                                        {transactions?.data?.count}
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <ul
                            data-role="listview"
                            data-inset="false"
                            id="searchInfo"
                            className="fieldHide ui-listview"
                            style={{ marginTop: "18px" }}
                        >
                            <li
                                className="pt-row-info ui-li-static ui-body-inherit ui-first-child"
                                style={{ padding: "8px" }}
                            >
                                <div className="tw-info tw-msg-info2"></div>
                                <div className="tw-popup-box">
                                    Vouchers transactions not found
                                </div>
                            </li>
                            <li
                                style={{ padding: "8px" }}
                                className="ui-li-static ui-body-inherit ui-last-child"
                            >
                                <div className="ui-btn ui-input-btn ui-btn-g ui-corner-all ui-shadow ui-mini ui-icon-recycle ui-btn-icon-right">
                                    Fermer
                                    <input
                                        type="button"
                                        value="Fermer"
                                        data-mini="true"
                                        data-icon="recycle"
                                        data-theme="g"
                                        data-iconpos="right"
                                    />
                                </div>
                            </li>
                        </ul>
                        <ul
                            id="playersList"
                            data-role="listview"
                            className="tw-ul-overview transactional-list ui-listview"
                            data-inset="false"
                            style={{ paddingTop: "17px" }}
                        >
                            {transactions &&
                                transactions?.data.map(
                                    (tr: any, index: any) => (
                                        <li className="pt-row" key={index}>
                                            {" "}
                                            <Link
                                                className="a-simple ui-link ui-btn ui-icon-arrow-r ui-btn-icon-right ui-shadow ui-corner-all ui-mini"
                                                to={`./${tr.id}`}
                                                data-role="button"
                                                data-icon="arrow-r"
                                                data-iconpos="right"
                                                data-mini="true"
                                                role="button"
                                            >
                                                <div className="ui-grid-a">
                                                    <div
                                                        className="ui-block-a tw-break-word tw-fsize11"
                                                        style={{
                                                            width: "75%",
                                                        }}
                                                    >
                                                        <div
                                                            className={`user-active  tw-bold`}
                                                        >
                                                            {getReferenceId(
                                                                tr.id
                                                            )}
                                                        </div>
                                                        <div className="tw-fsize11 tw-fwn">
                                                            <span className="tw-bold">
                                                                date :{" "}
                                                            </span>
                                                            {formatDate2(
                                                                tr.created_at
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="ui-block-b tw-text-right  tw-fsize11 tw-bold"
                                                        style={{
                                                            width: "25%",
                                                        }}
                                                    >
                                                        {tr.vouchers.length}
                                                    </div>
                                                </div>
                                            </Link>
                                        </li>
                                    )
                                )}
                        </ul>
                    </div>
                </div>
            </Header>
        </>
    );
}
