// @ts-nocheck

import React, { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../context/auth/auth-context";
import { useLoading } from "../context/loading-context";
import { numberFormat } from "../utils/date-utils";
import PopupInfo from "./popup-info";
import ChatwootWidget from "./chatwootWidget";

export default function Header({
  text,
  children,
  setIsSuccess,
  isSuccessConfig,
}) {
  const navigate = useNavigate();

  const { loader, setLoader } = useLoading();

  const { user, organization_data, refreshUserSimple } = useAuth();

  let wait = (ms) => new Promise((r, j) => setTimeout(r, ms));

  const pageRef = useRef();

  const [EnabledAvaiHeight, setEnabledAvaiHeight] = React.useState(true);

  const [avaiHeight, setAvaiHeight] = React.useState(
    window.screen.availHeight - 150
  );

  React.useEffect(() => {
    let availableHeight = pageRef.current.scrollHeight;

    if (availableHeight >= 1000) {
      setEnabledAvaiHeight(false);
    } else {
      setEnabledAvaiHeight(true);
    }
  });

  const UserRefresh = async () => {
    setLoader(true);
    await wait(100);
    refreshUserSimple();
    setLoader(false);
  };

  return (
    <>
      <div
        data-role="page"
        ref={pageRef}
        id="cashier"
        data-url="cashier_aspx"
        data-lang="en"
        tabIndex="0"
        className="ui-page ui-page-theme-a ui-page-active"
        style={EnabledAvaiHeight ? { minHeight: avaiHeight } : {}}
      >
        <div
          data-tap-toggle="false"
          data-role="header"
          data-theme="b"
          className="tw-header ui-header ui-bar-b"
          data-add-back-btn="true"
          data-back-btn-text="."
          role="banner"
        >
          <a
            role="button"
            onClick={() => navigate(-1)}
            className="ui-btn ui-corner-all ui-shadow ui-btn-left ui-btn-a ui-toolbar-back-btn ui-icon-carat-l ui-btn-icon-left"
            data-rel="back"
          >
            &nbsp;
          </a>
          <h1
            className="tw-header-title ui-title"
            role="heading"
            aria-level="1"
          >
            {text}
          </h1>
        </div>
        <div data-role="content" className="ui-content" role="main">
          <ul
            data-role="listview"
            data-inset="false"
            style={{ marginBottom: "17px", marginTop: "-1.1em" }}
            className="ui-listview"
          >
            <li className="ui-li-admin-account ui-li-static ui-body-inherit ui-first-child ui-last-child">
              <div className="ui-grid-a">
                <div
                  className="ui-block-a tw-inline tw-bold"
                  style={{
                    lineHeight: "18px",
                    paddingLeft: "8px",
                    paddingTop: "4px",
                    whiteSpace: "nowrap",
                  }}
                >
                  <span className="ui-icon-user ui-btn-icon-left"></span>{" "}
                  {user.username} :
                  <span id="adminBalance">
                    {" "}
                    {numberFormat(user.balance, 2)}{" "}
                    {organization_data?.currency}
                  </span>
                </div>
                <div className="ui-block-b">
                  <Link
                    to="/"
                    className="ui-btn ui-btn-c ui-btn-inline ui-icon-home ui-btn-icon-notext tw-btn-rounded"
                  >
                    I
                  </Link>
                  <form className="nested-form" id="reloadForm">
                    <input
                      type="hidden"
                      name="routeAction"
                      value="account/balance"
                    />
                    <div
                      onClick={UserRefresh}
                      className="ui-btn ui-input-btn ui-btn-c ui-corner-all ui-shadow ui-btn-inline ui-icon-refresh ui-btn-icon-notext"
                    >
                      <input
                        type="button"
                        data-icon="refresh"
                        data-theme="c"
                        data-inline="true"
                        data-iconpos="notext"
                        className="tw-btn-rounded"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </li>
          </ul>
          {children}
          {/*<ChatwootWidget user={user} />*/}
        </div>
      </div>
      {isSuccessConfig?.state && isSuccessConfig?.type === "popup" && (
        <PopupInfo
          message={isSuccessConfig?.message}
          type="popup"
          setIsSuccess={setIsSuccess}
        />
      )}
    </>
  );
}
