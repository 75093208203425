// @ts-nocheck
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { AuthProvider } from "./auth/auth-context";
import { LoadingProvider } from "./loading-context";
import { getOrgConfig } from "../utils/orgs-utils";

//import styles
import "../jqm-styles.css";

//import "../app-styles.css";

const DefaultTheme = React.lazy(() => import("../themes/default/theme"));
const GoldbetTheme = React.lazy(() => import("../themes/goldbet/theme"));
const TorobetTheme = React.lazy(() => import("../themes/torobet/theme"));
const IcebetTheme = React.lazy(() => import("../themes/icebet/theme"));
const Apollo365Theme = React.lazy(() => import("../themes/apollo365/theme"));

const Torbet365Theme = React.lazy(() => import("../themes/torbet365/theme"));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function AppProviders({ children }) {
  let organization = getOrgConfig();

  let { org_theme } = organization;

  let themeComponent;
  if (org_theme === "localhost") {
    themeComponent = <DefaultTheme />;
  } else if (org_theme === "goldbet365.org") {
    themeComponent = <GoldbetTheme />;
  } else if (org_theme === "torobet365.org") {
    themeComponent = <TorobetTheme />;
  } else if (org_theme === "icebet365.com") {
    themeComponent = <IcebetTheme />;
  } else if (org_theme === "apollo365.net") {
    themeComponent = <Apollo365Theme />;
  } else if (org_theme === "lyb.apollo365.net") {
    themeComponent = <Apollo365Theme />;
  } else if (org_theme === "torbet365.com") {
    themeComponent = <Torbet365Theme />;
  } else {
    themeComponent = <DefaultTheme />;
  }

  return (
    <>
      <React.Suspense fallback={<></>}>
        <QueryClientProvider client={queryClient}>
          <Router>
            <LoadingProvider>
              <AuthProvider organization={organization}>
                {themeComponent}
                {children}
              </AuthProvider>
            </LoadingProvider>
          </Router>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </React.Suspense>
    </>
  );
}

export { AppProviders };
