// @ts-nocheck
import { Storage } from "@capacitor/storage";
import axios from "axios";

const localStorageKey = "__auth_provider_token__";

const orgStorageKey = "__organization__";

async function getToken() {
  const { value } = await Storage.get({ key: localStorageKey });

  return value;
}

async function getOrganization() {
  const { value } = await Storage.get({ key: orgStorageKey });

  return JSON.parse(value);
}

async function getOrganizationId() {
  let organization = await getOrganization();

  if (organization) {
    return organization?.id;
  }
}

async function setOrganization(json) {
  await Storage.set({
    key: orgStorageKey,
    value: JSON.stringify(json),
  });
}

async function clearToken() {
  await Storage.remove({ key: localStorageKey });
}

async function setToken(token: string) {
  await Storage.set({
    key: localStorageKey,
    value: token,
  });
}

function handleUserResponse(user: any) {
  setToken(user.token);

  return user;
}

function login({ username, password }: any) {
  return client("login", { username, password }).then(handleUserResponse);
}

function register({ username, password }: any) {
  return client("register", { username, password }).then(handleUserResponse);
}

function handleUserLogout(response: any) {
  if (response.message === "User logged out successfully") {
    window.localStorage.clear();
  }
}

async function logout() {
  await clearToken();
}

const authURL = process.env.REACT_APP_API_URL;

const WHOIS_APP = process.env.REACT_APP_WHOIS_APP;

async function client(endpoint: any, data: any) {
  const config = {
    method: data ? "POST" : "GET",
    body: JSON.stringify(data),
    headers: { "Content-Type": "application/json", whoisapp: WHOIS_APP },
  };

  return window
    .fetch(`${authURL}/${endpoint}`, config)
    .then(async (response) => {
      const data = await response.json();

      if (data.status === true) {
        return data;
      } else {
        return Promise.reject(data);
      }
    });
}

async function client_v2(
  endpoint,
  { data, token, headers: customHeaders, ...customConfig } = {}
) {
  const config = {
    method: data ? "POST" : "GET",
    mode: "cors",
    body: data ? JSON.stringify(data) : undefined,
    headers: {
      Authorization: token ? `Bearer ${token}` : undefined,
      "Content-Type": "application/json",
      ...customHeaders,
    },
    ...customConfig,
  };

  return window
    .fetch(`${authURL}/${endpoint}`, config)
    .then(async (response) => {
      if (response.status === 401) {
        //queryCache.clear();
        await clearToken();
        // refresh the page for them
        window.location.assign("/");
        return Promise.reject({ message: "Please re-authenticate." });
      }

      const data = await response.json();
      if (data.status === true) {
        return data;
      } else {
        return Promise.reject(data);
      }
    });
}

const cms_api_baseURL = process.env.REACT_APP_API_URL;

const api_cms = axios.create({
  baseURL: cms_api_baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

api_cms.interceptors.request.use(async (config) => {
  let { url } = config;

  if (url === "login") {
    config.headers["organization-id"] = await getOrganizationId();
  } else {
    let token = await getToken();
    config.headers["Authorization"] = `Bearer ${token}`;
  }

  return config;
});

api_cms.interceptors.response.use(
  async (response) => {
    let { status: statusCode, data } = response;

    if (data.status === true) {
      return data;
    } else {
      if (statusCode === 401) {
        await logout();
      }

      return Promise.reject(data);
    }
  },
  async (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        await logout();
      }

      return Promise.reject(error.response.data);
    } else {
      // Handle other types of errors (network, timeouts, etc.)
      return Promise.reject(error.message);
    }
  }
);

export {
  getToken,
  clearToken,
  getOrganization,
  setOrganization,
  handleUserResponse,
  login,
  register,
  logout,
  localStorageKey,
  client,
  client_v2,
  api_cms,
};
