import React from "react";
import { numberFormat } from "../utils/date-utils";

interface Props {
  handleSetKeypad: (value: any) => void;
  handleHideKeypad: () => void;
  initialValue: string;
}

const Keypad: React.FC<Props> = ({
  initialValue,
  handleSetKeypad,
  handleHideKeypad,
}) => {
  const [value, setValue] = React.useState(String(initialValue));

  const [choice, setChoice] = React.useState(String(""));

  function showAsFloat(num: any, n: number) {
    return !isNaN(+num) ? (+num).toFixed(n || 2) : num;
  }

  function formatValue(newvalue: any) {
    let append;

    if (choice === "stop") return value;

    if (newvalue === ".") setChoice(".");

    if (choice === ".") {
      if (value?.split(".")[1].slice(0, 1) === "0") {
        if (newvalue != ".") {
          append = showAsFloat(
            parseFloat(value) + ("." + newvalue),
            2
          ).toString();
        }
      } else if (value?.split(".")[1].slice(1, 2) === "0") {
        append = showAsFloat(parseFloat(value) + newvalue, 2).toString();
      } else {
        append = value;
        setChoice("stop");
      }
    } else if (value.length < 13) {
      append = showAsFloat(parseFloat(value) + newvalue, 2).toString();
    } else {
      append = value;
    }

    setValue(append);
  }

  function CformatValue(value: any) {
    let new_val;
    let val = value.split(".");

    if (val[1] != "00") {
      if (val[1].slice(1, 2) != "0") {
        new_val = val[0] + "." + val[1].slice(0, 1) + "0";
      } else if (val[1].slice(0, 1) != "0") {
        new_val = val[0] + "." + "0" + val[1].slice(1, 2);
      } else {
        new_val = val[0] + "." + "00";
      }
    } else {
      if (val[0].length === 1) {
        return "0." + val[1];
      }

      new_val = val[0].slice(0, val[0].length - 1).toString() + "." + val[1];
    }

    setChoice("");
    return new_val;
  }

  function handleKeyClick(event: any) {
    if (event.target.value === "C") {
      setValue(CformatValue(value));
    } else {
      formatValue(event.target.value);
    }
  }

  return (
    <div id="keypadWrapper">
      <div id="keypadView" data-enhanced="true">
        <div className="ui-grid-solo">
          <div className="ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset">
            <input
              type="text"
              value={value}
              readOnly
              id="keypadAmount"
              className="ui-disabled"
              style={{ opacity: 1, fontWeight: "bold" }}
              placeholder="Montant"
            />
          </div>
        </div>
        <div className="ui-grid-b">
          <div className="ui-block-a">
            <div className="ui-btn ui-input-btn ui-corner-all ui-shadow ui-mini">
              1
              <input
                type="button"
                data-mini="true"
                defaultValue={"1"}
                onClick={handleKeyClick}
              />
            </div>
          </div>
          <div className="ui-block-b">
            <div className="ui-btn ui-input-btn ui-corner-all ui-shadow ui-mini">
              2
              <input
                type="button"
                data-mini="true"
                defaultValue={"2"}
                onClick={handleKeyClick}
              />
            </div>
          </div>
          <div className="ui-block-c">
            <div className="ui-btn ui-input-btn ui-corner-all ui-shadow ui-mini">
              3
              <input
                type="button"
                data-mini="true"
                defaultValue={"3"}
                onClick={handleKeyClick}
              />
            </div>
          </div>
          <div className="ui-block-a">
            <div className="ui-btn ui-input-btn ui-corner-all ui-shadow ui-mini">
              4
              <input
                type="button"
                data-mini="true"
                defaultValue={"4"}
                onClick={handleKeyClick}
              />
            </div>
          </div>
          <div className="ui-block-b">
            <div className="ui-btn ui-input-btn ui-corner-all ui-shadow ui-mini">
              5
              <input
                type="button"
                data-mini="true"
                defaultValue={"5"}
                onClick={handleKeyClick}
              />
            </div>
          </div>
          <div className="ui-block-c">
            <div className="ui-btn ui-input-btn ui-corner-all ui-shadow ui-mini">
              6
              <input
                type="button"
                data-mini="true"
                defaultValue={"6"}
                onClick={handleKeyClick}
              />
            </div>
          </div>
          <div className="ui-block-a">
            <div className="ui-btn ui-input-btn ui-corner-all ui-shadow ui-mini">
              7
              <input
                type="button"
                data-mini="true"
                defaultValue={"7"}
                onClick={handleKeyClick}
              />
            </div>
          </div>
          <div className="ui-block-b">
            <div className="ui-btn ui-input-btn ui-corner-all ui-shadow ui-mini">
              8
              <input
                type="button"
                data-mini="true"
                defaultValue={"8"}
                onClick={handleKeyClick}
              />
            </div>
          </div>
          <div className="ui-block-c">
            <div className="ui-btn ui-input-btn ui-corner-all ui-shadow ui-mini">
              9
              <input
                type="button"
                data-mini="true"
                defaultValue={"9"}
                onClick={handleKeyClick}
              />
            </div>
          </div>
          <div className="ui-block-a">
            <div className="ui-btn ui-input-btn ui-corner-all ui-shadow ui-mini">
              .
              <input
                type="button"
                data-mini="true"
                defaultValue="."
                onClick={handleKeyClick}
              />
            </div>
          </div>
          <div className="ui-block-b">
            <div className="ui-btn ui-input-btn ui-corner-all ui-shadow ui-mini">
              0
              <input
                type="button"
                data-mini="true"
                defaultValue={"0"}
                onClick={handleKeyClick}
              />
            </div>
          </div>
          <div className="ui-block-c">
            <div className="ui-btn ui-input-btn ui-corner-all ui-shadow ui-mini">
              C
              <input
                type="button"
                data-mini="true"
                data-cmd="resetAmount"
                defaultValue="C"
                onClick={handleKeyClick}
              />
            </div>
          </div>
        </div>
        <div className="ui-grid-a" style={{ marginTop: "8px" }}>
          <div className="ui-block-a">
            <div className="ui-btn ui-input-btn ui-btn-e ui-corner-all ui-shadow ui-mini ui-icon-delete ui-btn-icon-left">
              Annuler
              <input
                type="button"
                data-cmd="cancelInput"
                defaultValue="Annuler"
                data-theme="e"
                data-mini="true"
                data-icon="delete"
                onClick={handleHideKeypad}
              />
            </div>
          </div>
          <div className="ui-block-b">
            <div className="ui-btn ui-input-btn ui-btn-c ui-corner-all ui-shadow ui-mini ui-icon-check ui-btn-icon-right">
              OK
              <input
                type="button"
                data-cmd="setTotalInput"
                defaultValue="OK"
                data-theme="c"
                data-icon="check"
                data-mini="true"
                data-iconpos="right"
                onClick={() => handleSetKeypad(value)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Keypad;
