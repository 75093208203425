// @ts-nocheck

import React from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { numberFormat, formatDate2 } from "../../utils/date-utils";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { VoucherSinglePrint } from "./voucher-single-print";

export default function VouchersList({ transaction, organization_data }) {
  return (
    <div
      id="topPlayers"
      aria-labelledby="ui-id-27"
      className="ui-tabs-panel ui-widget-content ui-corner-bottom"
      role="tabpanel"
      aria-expanded="true"
      aria-hidden="false"
      style={{ display: "block" }}
    >
      <div style={{ marginTop: "12px" }}>
        <div className="ui-input-search ui-body-inherit ui-corner-all ui-mini ui-shadow-inset ui-input-has-clear">
          <input id="searchPlayers" data-mini="true" data-type="search" />
          <a
            href="#"
            tabIndex={-1}
            aria-hidden="true"
            className="ui-input-clear ui-btn ui-icon-delete ui-btn-icon-notext ui-corner-all ui-input-clear-hidden"
            title="Clear text"
          >
            Clear text
          </a>
        </div>
      </div>
      <ul
        data-role="listview"
        data-inset="true"
        className="ul-simple transactional-list ui-listview ui-listview-inset ui-corner-all ui-shadow"
        data-filter="true"
        data-input="#searchPlayers"
      >
        <li
          data-role="list-divider"
          className="tw-ui-li-divider ui-li-divider ui-bar-b ui-bar-inherit ui-first-child"
          role="heading"
        >
          <div className="ui-grid-a tw-fsize11">
            <div className="ui-block-a tw-inline">Coupon</div>
            <div className="ui-block-b tw-inline tw-text-rintent10">Status</div>
          </div>
        </li>
        <li className="pt-row ui-li-static ui-body-inherit">
          <div className="ui-grid-a tw-fsize11 text-g">
            <div className="ui-block-a tw-inline tw-bold">Total :</div>
            <div className="ui-block-b tw-inline tw-text-right tw-bold">
              {transaction.vouchers.length}
            </div>
          </div>
        </li>
        {transaction.vouchers &&
          transaction.vouchers.map((v: any, index: any) => (
            <VoucherItem
              v={v}
              transaction={transaction}
              organization_data={organization_data}
              key={index}
            />
          ))}
        <li className="ui-li-static ui-body-inherit ui-last-child">
          <div className="ui-btn ui-input-btn ui-btn-e ui-corner-all ui-shadow ui-mini ui-icon-arrow-u ui-btn-icon-right">
            Top
            <input
              type="button"
              data-theme="e"
              defaultValue="Top"
              data-icon="arrow-u"
              data-iconpos="right"
              data-mini="true"
            />
          </div>
        </li>
      </ul>
    </div>
  );
}

function VoucherItem({ transaction, organization_data, v }) {
  const printDomRef = React.createRef();

  let { currency } = organization_data;

  return (
    <>
      <ReactToPrint
        trigger={() => {
          return (
            <li className="pt-row">
              <a
                className={`a-simple ui-link ui-btn  ui-btn-icon-right ui-shadow ui-corner-all ui-mini ${
                  v.is_redeemed === true
                    ? "ui-icon-check bg-blue"
                    : "ui-icon-clock"
                }`}
                ata-role="button"
                data-icon="arrow-r"
                data-iconpos="right"
                data-mini="true"
                role="button"
                href={void 0}
              >
                <div className="ui-grid-a">
                  <div
                    className="ui-block-a tw-break-word tw-fsize11"
                    style={{ width: "75%" }}
                  >
                    <div>
                      <span>code : </span>
                      <span className="user-active tw-bold">{v.code}</span>
                    </div>

                    <div>
                      <span>montant : </span>
                      <span className="tw-fsize11 tw-bold">
                        {numberFormat(v.amount, 2)} {currency}
                      </span>
                    </div>

                    <div>
                      <span>expiré : </span>
                      <span className="tw-fsize11 tw-bold">
                        {formatDate2(v.expire_at)}
                      </span>
                    </div>

                    <div>
                      <span>consumé par : </span>
                      <span className="tw-fsize11 tw-bold">
                        {v.redeemed_by_user?.username || "aucune"}
                      </span>
                    </div>
                  </div>
                </div>
              </a>
            </li>
          );
        }}
        documentTitle={`Coupon ${v.id}`}
        content={() => printDomRef.current}
      />

      <div style={{ display: "none" }}>
        <VoucherSinglePrint
          ref={printDomRef}
          voucher={v}
          organization_data={organization_data}
          transaction={transaction}
        />
      </div>
    </>
  );
}
