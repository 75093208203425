// @ts-nocheck

import React, { useRef } from "react";
import { Disclosure, Transition } from "@headlessui/react";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import Info from "../../components/info";
import Header from "../../components/Header";
import { useQuery } from "react-query";
import { api_cms } from "../../context/auth/auth-provider";
import { isEmpty, amountFormat, capitalize } from "../../utils/orgs-utils";
import { useAuth } from "../../context/auth/auth-context";

import {
  RiShieldStarFill,
  RiVipCrown2Fill,
  RiProductHuntFill,
  RiShieldCheckFill,
  RiShieldFlashFill,
  RiShieldKeyholeFill,
  RiShieldUserFill,
  RiStarFill,
  RiStarHalfFill,
  RiAccountPinCircleFill,
  RiUserStarFill,
} from "react-icons/ri";

import {
  TbBadgeFilled,
  TbBadgesFilled,
  TbArrowBadgeDownFilled,
  TbCircleLetterP,
  TbStarHalfFilled,
  TbUserBolt,
} from "react-icons/tb";

import { IoStorefront } from "react-icons/io5";

const getRoleDisplay = (role) => {
  switch (role) {
    case "super":
      return { color: "#ca8a04", Icon: RiShieldStarFill };
      break;
    case "admin":
      return { color: "#b91c1c", Icon: TbBadgeFilled };

      break;
    case "agent":
      return { color: "#4338ca", Icon: TbBadgesFilled };

      break;
    case "shop":
      return { color: "#059669", Icon: IoStorefront };

      break;
    case "player":
      return { color: "#0284c7", Icon: TbUserBolt };

    default:
      return "#78716c";
  }
};

function isHasChilds(open, data) {
  if (open === true && !isEmpty(data)) return false;

  return true;
}

function UserTree() {
  const { user, organization_data } = useAuth();

  const { currency } = organization_data;

  const { data, isLoading, isError, error, refetch } = useQuery(
    `tree`,
    async (data) => {
      return api_cms.get(`tree`);
    }
    /*{
      onSuccess: (data) => {
        console.log({ data });
      },
    }*/
    /*{
      select: (data) => {
        return data.data;
      },
    }*/
  );

  return (
    <>
      <Header text={`${capitalize(user.role)} Cercuit`}>
        <div id="twMainContentView">
          <div
            id="payment"
            aria-labelledby="ui-id-16"
            className="ui-tabs-panel ui-widget-content ui-corner-bottom"
            role="tabpanel"
            aria-expanded="true"
            aria-hidden="false"
          >
            <div
              className="ui-collapsible-content"
              style={{ paddingBottom: "30%" }}
              aria-hidden="false"
            >
              {" "}
              <ul
                data-role="listview"
                data-inset="true"
                className="ui-listview ui-l'istview-inset ui-corner-all ui-shadow ul-tree"
              >
                {data && (
                  <Disclosure defaultOpen={true}>
                    {({ open }) => {
                      let { Icon, color } = getRoleDisplay(data.role);

                      return (
                        <>
                          <li
                            className="ui-first-child"
                            style={{ paddingBottom: "10px" }}
                          >
                            <Disclosure.Button
                              as="a"
                              className={`tw-white-space-normal ui-link ui-btn ui-icon-arrow-${
                                !isHasChilds(open, data.children) ? "d" : "r"
                              } ${
                                data.role != "player" && "ui-btn-icon-right "
                              } ui-shadow ui-corner-all ui-mini tree`}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-start",
                                  }}
                                >
                                  <span style={{ marginRight: "5px" }}>
                                    <Icon
                                      style={{
                                        height: "1.5em",
                                        width: "1.5em",
                                        color: color,
                                      }}
                                    />
                                  </span>

                                  <span style={{ fontWeight: "bold" }}>
                                    {data.username}
                                  </span>
                                  <span style={{ color: "grey" }}>
                                    {data.children
                                      ? `(${data.children.length})`
                                      : `(0)`}
                                  </span>
                                </div>

                                <span style={{ fontWeight: "bold" }}>
                                  {amountFormat(data.balance)}
                                  <span
                                    style={{
                                      color: "grey",
                                      marginLeft: "3px",
                                      fontSize: "12px",
                                      fontWeight: "normal",
                                    }}
                                  >
                                    {currency}
                                  </span>
                                </span>
                              </div>
                            </Disclosure.Button>

                            <Transition
                              show={open}
                              enter="transition duration-100 ease-out"
                              enterFrom="transform scale-95 opacity-0"
                              enterTo="transform scale-100 opacity-100"
                              leave="transition duration-75 ease-out"
                              leaveFrom="transform scale-100 opacity-100"
                              leaveTo="transform scale-95 opacity-0"
                            >
                              <Disclosure.Panel>
                                <ul data-role="listview" data-inset="true">
                                  <UserChildren
                                    children={data.children}
                                    currency={currency}
                                  />
                                </ul>
                              </Disclosure.Panel>
                            </Transition>
                          </li>
                        </>
                      );
                    }}
                  </Disclosure>
                )}
              </ul>
            </div>
          </div>
        </div>
      </Header>
    </>
  );
}

function UserChildren({ children, currency }) {
  if (isEmpty(children)) {
    return;
  }

  if (isEmpty(children.length)) {
    return;
  }

  return (
    <>
      {children.map((user, i) => (
        <Disclosure key={i}>
          {({ open }) => {
            let { Icon, color } = getRoleDisplay(user.role);

            return (
              <>
                <li className="ui-first-child">
                  <Disclosure.Button
                    as="a"
                    className={`tw-white-space-normal ui-link ui-btn ui-icon-arrow-${
                      !isHasChilds(open, user.children) ? "d" : "r"
                    } ${
                      user.role != "player" && "ui-btn-icon-right "
                    } ui-shadow ui-corner-all ui-mini tree`}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                      >
                        <span style={{ marginRight: "5px" }}>
                          <Icon
                            style={{
                              height: "1.5em",
                              width: "1.5em",
                              color: color,
                            }}
                          />
                        </span>
                        <span style={{ fontWeight: "bold" }}>
                          {user.username}
                        </span>
                        {user.role != "player" && (
                          <span style={{ color: "grey" }}>
                            {user.children
                              ? `(${user.children.length})`
                              : `(0)`}
                          </span>
                        )}
                      </div>

                      <span style={{ fontWeight: "bold" }}>
                        {amountFormat(user.balance)}
                        <span
                          style={{
                            color: "grey",
                            marginLeft: "3px",
                            fontSize: "12px",
                            fontWeight: "normal",
                          }}
                        >
                          {currency}
                        </span>
                      </span>
                    </div>
                  </Disclosure.Button>

                  <Transition
                    enter="transition duration-100 ease-out"
                    enterFrom="transform scale-95 opacity-0"
                    enterTo="transform scale-100 opacity-100"
                    leave="transition duration-75 ease-out"
                    leaveFrom="transform scale-100 opacity-100"
                    leaveTo="transform scale-95 opacity-0"
                  >
                    <Disclosure.Panel>
                      <ul data-role="listview" data-inset="true">
                        <UserChildren
                          children={user.children}
                          currency={currency}
                        />
                      </ul>
                    </Disclosure.Panel>
                  </Transition>
                </li>
              </>
            );
          }}
        </Disclosure>
      ))}
    </>
  );
}

export default UserTree;
